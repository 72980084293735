import { useQuery } from "@tanstack/react-query";
import {
  UserCaseStudiesGet,
  UserCaseStudiesGetTag,
  UserDataGet,
  UserDataGetTag,
  UserJobPostsGet,
  UserJobPostsGetTag,
} from "@/data/queries";
import toast from "react-hot-toast";
import {
  TypographyH3,
  TypographyH4, TypographyP,
  TypographySmall,
} from "@/components/Typography";
import { Button } from "@/components/ui/button";
import { Link } from "@tanstack/react-router";
import { ScrollArea } from "@/components/ui/scroll-area";

export const ActionCenter = () => {
  const { data: JobPostsData, isLoading: jobPostLoading } = useQuery({
    queryKey: [UserJobPostsGetTag],
    queryFn: () => UserJobPostsGet(),
    onError: () => toast.error("failed to load job posts"),
  });
  const { data: UserCaseStudiesCount, isLoading: caseStudyLoading } = useQuery({
    queryKey: [UserCaseStudiesGetTag],
    queryFn: () => UserCaseStudiesGet(),
    onError: () => toast.error("failed to load job posts"),
  });
  const { data: userData, isLoading: userDataLoading } = useQuery({
    queryKey: [UserDataGetTag],
    queryFn: () => UserDataGet(),
    onError: () => toast.error("failed to load profile"),
  });


  return (
    <div className={"flex max-sm:flex-col sm:space-x-4 px-2 min-h-screen sm:items-start"}>
      <div className={"border border-stone-300 rounded-lg px-4 py-3 sm:flex-1 max-h-fit"}>
        <TypographyH3>Hire Talent</TypographyH3>
        {!jobPostLoading && !JobPostsData && (
          <div className={"mt-2"}>
            <Link to={"/home/post-job"}>
              <Button isSpan={true} className={"px-0"} variant={"actionUnderline"}>
                Make your first job post
              </Button>
            </Link>
          </div>
        )}
        {!jobPostLoading && JobPostsData && (
          <div className={"mt-4"}>
            <Link className={""} to={"/home/post-job"}>
              <Button isSpan={true}>Create a new job post</Button>
            </Link>
            <TypographyH4 class={"mt-4 mb-2"}>Your job posts</TypographyH4>
            <ScrollArea className={"max-h-[200px] sm:max-h-[600px] overflow-y-scroll border-t border-gray-300"}>
              {JobPostsData.map((item) => {
                return (
                  <div className={"my-2"} key={item.jobPostID}>
                    <Link
                      to={"/home/jobs/$jobPostID"}
                      params={{ jobPostID: item.jobPostID }}
                    >
                      <Button isSpan={true} variant={"actionUnderline"}>
                        {item.jobPostTitle}
                      </Button>
                    </Link>
                    <TypographySmall class={"text-gray-500 ml-2"}>
                      {item.replies} replies
                    </TypographySmall>
                  </div>
                );
              })}
            </ScrollArea>
          </div>
        )}
      </div>
      <div className={"max-sm:mt-4 border border-stone-300 rounded-lg px-4 py-3  sm:flex-1 max-h-fit"}>
        <TypographyH3>Get Hired</TypographyH3>
        {userData?.primarilyJobSeeker}
        {!userDataLoading && !userData?.bio.Valid && userData?.primarilyJobSeeker === true && userData?.userID && (
          <div className={"mt-2"}>
            <Link
              to={"/home/profile/$userID/edit"}
              params={{ userID: userData.userID }}
            >
              <Button className={"px-0"} variant={"actionUnderline"}>Update your profile</Button>
            </Link>
            <TypographyP class={"pl-4"}>👆 You won't be able to apply to jobs until you add your bio. You can turn this warning off by updating your settings in your profile as well.</TypographyP>
          </div>
        )}
        {/*{!caseStudyLoading && UserCaseStudiesCount?.count === 0 && (*/}
        {/*  <div className={"mt-2"}>*/}
        {/*    <Button variant={"actionUnderline"}>*/}
        {/*      Post your first case study*/}
        {/*    </Button>*/}
        {/*  </div>*/}
        {/*)}*/}
        <Link
            to={"/home/job-posts/$page"}
            params={{page: "1"}}
        >
          <Button className={"px-0"} variant={"actionUnderline"}>Browse Jobs</Button>
        </Link>
      </div>
    </div>
  );
};
