import React from "react";

import { RecordState } from "../../../types/mux";
import { Button } from "../ui/button";

type Props = {
  recordState: RecordState;
  isLoadingPreview: boolean;
  isReviewing: boolean;
  startRecording: () => void;
  cancelRecording: () => void;
  stopRecording: () => void;
  submitRecording: () => void;
  reset: () => void;
};

const RecordingControls: React.FC<Props> = ({
  recordState,
  isLoadingPreview,
  isReviewing,
  startRecording,
  cancelRecording,
  stopRecording,
  submitRecording,
  reset,
}) => {
  const renderRecordingControl = React.useCallback(() => {
    if (isReviewing) {
      return null;
    } else if (recordState === RecordState.IDLE) {
      return (
        <Button
          type="button"
          variant={"default"}
          className={"bg-orange-500 hover:bg-orange-600 mt-4"}
          onClick={startRecording}
        >
          Step three: 👉 Start recording
        </Button>
      );
    } else if (recordState === RecordState.PREPARING) {
      return (
        <Button type="button" className={"my-4"} onClick={cancelRecording}>
          Cancel recording
        </Button>
      );
    } else if (RecordState.RECORDING) {
      return (
        <Button type="button" className={"my-4"} onClick={stopRecording}>
          Stop recording
        </Button>
      );
    }
  }, [recordState, isReviewing]);

  return (
    <div className="container">
      <div className="button">{renderRecordingControl()}</div>
      <div className="button">
        <Button
          className={"mt-4"}
          type="button"
          onClick={submitRecording}
          disabled={!isReviewing || isLoadingPreview}
        >
          {isLoadingPreview ? "Loading preview..." : "Submit Video"}
        </Button>
      </div>
      <div className="button">
        <Button
          type="button"
          className={"bg-red-500 hover:bg-red-600 my-4"}
          onClick={reset}
        >
          Reset
        </Button>
      </div>
    </div>
  );
};

export default RecordingControls;
