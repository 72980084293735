import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import MuxUploader from "@mux/mux-uploader-react";
import type { MuxUploaderProps } from "@mux/mux-uploader-react";
import useSwr from "swr";
import { TypographyLarge, TypographyP } from "@/components/Typography";
import { Button } from "./ui/button";
import { useParams } from "@tanstack/react-router";
import toast from "react-hot-toast";

const fetcher = (url: string) =>
  fetch(url, { credentials: "include" }).then((res) => res.json());

type Props = null;

type ChunkInfo = {
  size: number;
  uploadStarted: number;
  uploadFinished?: number;
};

type UploadTelemetry = {
  fileSize: number;
  uploadStarted: number;
  uploadFinished?: number;
  dynamicChunkSize?: boolean;
  chunkSize: number;
  chunks: ChunkInfo[];
};

export const MuxUpload = () => {
  const { jobQuestionID, jobApplicationID } = useParams({
    from: "/home/upload-video/$jobQuestionID/$jobApplicationID",
  });
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadId, setUploadId] = useState<string>("");
  const [isPreparing, setIsPreparing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [uploadAnalytics, setUploadAnalytics] = useState<
    Partial<UploadTelemetry> & Pick<UploadTelemetry, "chunks">
  >({ chunks: [] });

  const { data } = useSwr(
    () =>
      isPreparing
        ? `${
            import.meta.env.VITE_API_HOST
          }/api/v1/job-video/${jobApplicationID}/${uploadId}`
        : null,
    fetcher,
    { refreshInterval: 1000 }
  );

  const upload = data && data.upload;

  const createUpload = async () => {
    console.log("trying to upload in mux component");
    try {
      return fetch(
        `${
          import.meta.env.VITE_API_HOST
        }/api/v1/job-video/${jobApplicationID}/${jobQuestionID}/new-video`,
        {
          method: "POST",
          credentials: "include",
        }
      )
        .then((res) => res.json())
        .then(({ id, url }) => {
          setUploadId(id);
          return url;
        });
    } catch (e) {
      console.error("Error in createUpload", e);
      setErrorMessage("Error creating upload.");
      return Promise.reject(e);
    }
  };

  const handleUpload: MuxUploaderProps["onUploadStart"] = ({ detail }) => {
    setIsUploading(true);

    const initialUploadAnalytics: UploadTelemetry = {
      fileSize: detail.file.size,
      chunkSize: detail.chunkSize,
      uploadStarted: Date.now(),
      dynamicChunkSize: isDynamicChunkSizeSet,
      chunks: [],
    };

    setUploadAnalytics(initialUploadAnalytics);
  };

  const handleChunkAttempt: MuxUploaderProps["onChunkAttempt"] = ({
    detail,
  }) => {
    const chunks: ChunkInfo[] = [...uploadAnalytics.chunks];
    chunks[detail.chunkNumber] = {
      size: detail.chunkSize,
      uploadStarted: Date.now(),
    };

    setUploadAnalytics({
      ...uploadAnalytics,
      chunks,
    });
  };

  const handleChunkSuccess: MuxUploaderProps["onChunkSuccess"] = ({
    detail,
  }) => {
    const chunks = [...uploadAnalytics.chunks];
    chunks[detail.chunk].uploadFinished = Date.now();
    chunks[detail.chunk].size = detail.chunkSize;

    setUploadAnalytics({
      ...uploadAnalytics,
      chunks,
    });
  };

  const handleSuccess: MuxUploaderProps["onSuccess"] = () => {
    const finalAnalytics = { ...uploadAnalytics };
    finalAnalytics.uploadFinished = Date.now();

    // fetch("/api/telemetry", {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     type: "upload",
    //     data: finalAnalytics,
    //   }),
    // });

    setIsPreparing(true);
  };

  const [isDynamicChunkSizeSet, setIsDynamicChunkSizeSet] = useState(false);
  useEffect(() => {
    const isDynamic: string = Cookies.get("dynamicChunkSize") || "";
    setIsDynamicChunkSizeSet(isDynamic === "true");

    if (upload && upload.asset_id) {
      toast.success("uploaded");
    }
  }, [upload]);

  if (errorMessage) {
    return (
      <div>
        <div style={{ paddingBottom: "20px" }}>
          <TypographyLarge>Error: {errorMessage}</TypographyLarge>
        </div>
        <div>
          <TypographyP>Try to reload the page</TypographyP>
        </div>
      </div>
    );
  }
  return (
    <MuxUploader
      onUploadStart={handleUpload}
      onChunkAttempt={handleChunkAttempt}
      onChunkSuccess={handleChunkSuccess}
      onSuccess={handleSuccess}
      dynamicChunkSize={isDynamicChunkSizeSet}
      id="uploader"
      endpoint={createUpload}
      style={{ fontSize: isUploading ? "4vw" : "26px" }}
    >
      <Button
        size={"xl"}
        className={isUploading ? "hidden" : ""}
        slot="file-select"
      >
        Upload video
      </Button>
    </MuxUploader>
  );
};
