import { useEffect } from "react";
import { Outlet, RootRoute, useMatch } from "@tanstack/react-router";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

export const rootRoute = new RootRoute({
  component: () => {
    const { route } = useMatch();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [window.location.pathname]);
    return (
      <>
        <Outlet /> {/* Start rendering router matches */}
        <ReactQueryDevtools position={"bottom-right"} />
        {/*<TanStackRouterDevtools position="bottom-left" />*/}
      </>
    );
  },
});
