import {Route, useParams} from "@tanstack/react-router";
import {Home} from "@/pages/home/Home";
import {queryClient} from "@/main";
import {JobGet, JobGetTag} from "@/data/queries";
import {useQuery} from "@tanstack/react-query";
import toast from "react-hot-toast";
import {EditOrPostJob} from "@/pages/home/job-post/EditOrPostJob";

export const JobEdit = new Route({
    path: "edit-job/$jobPostID",
    getParentRoute: () => Home,
    onLoad: async ({ params: { jobPostID } }) => {
        queryClient.ensureQueryData([JobGetTag, jobPostID], () => JobGet(jobPostID));
    },
    component: () => {
        const { jobPostID } = useParams({ from: "/home/jobs/$jobPostID" });
        const { data, isLoading } = useQuery({
            queryKey: [JobGetTag, jobPostID],
            queryFn: () => JobGet(jobPostID),
            onError: () => toast.error("failed to load job"),
        });
        if(isLoading || !data){
            return <div/>
        }
        return (
            <div>
                <EditOrPostJob Data={data} IsEdit={true}/>
            </div>
        )
    }
})