import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from "@/components/ui/dialog";
import {Button} from "@/components/ui/button";

type HelpIconProps = {
    children?: JSX.Element; // label element
    className?: string;
    dialogHeader: string;
    dialogBody: JSX.Element;
    trigger?: string
};


export const HelpIcon = (props: HelpIconProps) => {
    return (
        <>
            <div
                className={`flex items-center ${props.className && props.className}`}
            >
                {props.children}
                <Dialog>
                    <DialogTrigger>
                        {" "}
                        <Button
                            isSpan={true}
                            type={"button"}
                            onClick={(e) => {}}
                            variant={"ghost"}
                            size={"sm"}
                            className={" ml-2 text-slate-600"}
                        >
                            {props.trigger ? props.trigger: "What's this?"}
                        </Button>
                    </DialogTrigger>
                    <DialogContent>
                        <DialogHeader>
                            <DialogTitle>{props.dialogHeader}</DialogTitle>
                        </DialogHeader>
                        {props.dialogBody}
                    </DialogContent>
                </Dialog>
            </div>
        </>
    );
};
