import { queryClient } from "@/main";
import { Link, Route } from "@tanstack/react-router";
import { Home } from "../Home";
import { useMutation, useQuery } from "@tanstack/react-query";
import { UserDataGet, UserDataGetTag } from "@/data/queries";
import toast from "react-hot-toast";
import { SubmitHandler, useForm } from "react-hook-form";
import { OrgUserProfileUpdateParams } from "../../../../types/myApi";
import { handleError } from "@/data/handleError";
import { ProfileUpdate } from "@/data/mutations";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import {
  TypographyH2,
  TypographyH4,
  TypographyP,
} from "@/components/Typography";
import ReactQuill from "react-quill";
import { useEffect, useRef, useState } from "react";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { AddPhoto } from "./addPhoto";
import { HelpIcon } from "@/components/HelpIcon";

export const EditProfile = new Route({
  getParentRoute: () => Home,
  path: "/profile/$userID/edit",
  onLoad: async () => {
    await queryClient.ensureQueryData([UserDataGetTag], () => UserDataGet());
  },
  component: () => {
    const { data: userData, isLoading: userDataLoading } = useQuery({
      queryKey: [UserDataGetTag],
      queryFn: () => UserDataGet(),
      onError: () => toast.error("failed to load profile"),
    });
    const [showSave, setShowSave] = useState(false);
    const {
      register,
      handleSubmit,
      setValue: setFormValue,
      formState,
    } = useForm<OrgUserProfileUpdateParams>({
      defaultValues: {
        bio: userData?.bio.String,
        primarilyJobSeeker: userData?.primarilyJobSeeker,
        id: userData?.userProfileID,
        linkedinProfile: userData?.linkedinProfile.String,
        title: userData?.title.String,
        timeZone: userData?.timeZone.String,
        calendarLink: userData?.calendarLink.String,
        YoutubeEmbedLink: userData?.youtubeEmbedLink.String,
      },
    });
    const focusRef = useRef<ReactQuill | null>(null);

    const [value, setValue] = useState("");
    const [formJobPosting, setFormJobPosting] = useState(true);

    useEffect(() => {
      setShowSave(true);
    }, [formState.touchedFields, value, formJobPosting]);

    const { mutateAsync, isLoading } = useMutation(
      (data: OrgUserProfileUpdateParams) =>
        ProfileUpdate(
          data,
          userData?.userProfileID ? userData.userProfileID : ""
        ),
      {
        onError: (err) => {
          toast.error(handleError(err));
        },
        onSuccess: () => {
          queryClient.invalidateQueries([UserDataGetTag]);
          toast.success("Updated");
        },
      }
    );

    useEffect(() => {
      if (userData && !formState.isDirty) {
        setFormJobPosting(userData.primarilyJobSeeker);
        setFormValue("linkedinProfile", userData.linkedinProfile.String);
        setFormValue("title", userData.title.String);
        setFormValue("timeZone", userData.timeZone.String);
        setValue(userData.bio.String);
      }
    }, [userData]);

    const onSubmit: SubmitHandler<OrgUserProfileUpdateParams> = async (
      data
    ) => {
      data.primarilyJobSeeker = formJobPosting;
      data.bio = value;
      mutateAsync(data).then((resp) => {});
    };

    if (!userData) {
      return <div />;
    }

    return (
      <div className={"px-4"}>
        <TypographyH2 class={"text-center mt-6"}>
          Update your profile
        </TypographyH2>
        <div className={"flex items-center"}>
          <Link
            to={"/home/profile/$userID/view"}
            params={{ userID: userData.userID }}
            className={"mx-auto"}
          >
            <Button variant={"link"} isSpan={true}>
              Preview your profile
            </Button>
          </Link>
        </div>
        <div className={"flex flex-col items-center justify-center mt-4"}>
          {userData.pictureLink.Valid ? (
            <div className={"w-[200px] h-[200px] rounded-full"}>
              <img
                className={"w-full h-full object-cover rounded-full"}
                loading={"lazy"}
                src={
                  import.meta.env.VITE_CDN_HOST + userData.pictureLink.String
                }
              />
            </div>
          ) : (
            <div
              className={"bg-blue-900 w-[200px] h-[200px] rounded-full"}
            ></div>
          )}
          <AddPhoto />
        </div>
        <form
          className={"max-w-[700px] mt-6 mx-auto"}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Label className={"mb-2 block mt-6"} htmlFor={"timeZone"}>
            I am primarily:
          </Label>
          <RadioGroup
            onValueChange={(v) => setFormJobPosting(v === "job-seeking")}
            className={"mb-2"}
            defaultValue="option-one"
          >
            <div className="flex items-center space-x-2">
              <RadioGroupItem
                value="job-seeking"
                id="option-one"
                checked={formJobPosting}
              />
              <Label htmlFor="option-one">Job Seeking</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem
                value="job-posting"
                id="option-two"
                checked={!formJobPosting}
              />
              <Label htmlFor="option-two">Job Posting</Label>
            </div>
          </RadioGroup>
          <Label className={"mb-2 block"} htmlFor={"title"}>
            Name
          </Label>
          <Input autoComplete={"name"} {...register("title")} />
          <Label className={"mb-2 block mt-6"} htmlFor={"timeZone"}>
            Working Time Zone
          </Label>
          <Input {...register("timeZone")} />
          <Label className={"mb-2 block mt-6"} htmlFor={"linkedinProfile"}>
            Linkedin Profile URL
          </Label>
          <Input {...register("linkedinProfile")} />
          <div className={"flex items-center mb-2 block mt-6"}>
            <Label className={""} htmlFor={"YoutubeEmbedLink"}>
              Youtube Video ID
            </Label>
            <HelpIcon
              dialogHeader={"How to find the Youtube Video ID"}
              dialogBody={
                <div>
                  <TypographyP class={"mb-2"}>
                    Every Youtube video has an ID. The below ID is{" "}
                    <pre className={"inline-block"}>dQw4w9WgXcQ</pre>
                  </TypographyP>
                  <TypographyP class={"mb-2"}>
                    Add the ID of your video in the field to have a Youtube
                    video embedded in your profile
                  </TypographyP>
                  <img loading={"eager"} src={"/youtube-id.png"} />
                  <TypographyP class={"font-bold my-2"}>Video type</TypographyP>
                  <TypographyP class={"mb-2"}>
                    You may use an unlisted video or a public video.
                  </TypographyP>
                </div>
              }
            />
          </div>
          <Input
            placeholder={"dQw4w9WgXcQ"}
            {...register("YoutubeEmbedLink")}
          />
          <Label className={"mb-2 block mt-6"} htmlFor={"calendarLink"}>
            Calendar Schedule Link
          </Label>
          <Input placeholder={"calendly.com"} {...register("calendarLink")} />
          <Label className={"mb-2 block mt-6"} htmlFor={"bio"}>
            Bio
          </Label>
          <div onClick={() => focusRef?.current?.focus()}>
            <ReactQuill
              ref={focusRef}
              placeholder={""}
              className={"mt-2 bg-white "}
              id={"bio"}
              theme="snow"
              value={value}
              onChange={setValue}
            />
          </div>
          <div className={"relative max-w-[700px] mx-auto h-[10px] mt-6"}>
            <div
              className={
                "fixed left-0 bottom-0 right-0 bg-gray-200 shadow-3xl py-2"
              }
            >
              <div className={"bg-gray-200  max-w-[700px] mx-auto max-sm:px-4"}>
                <Button disabled={isLoading} className={""}>
                  Save Profile
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  },
});
