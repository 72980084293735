import { Route, useParams } from "@tanstack/react-router";
import { Home } from "../Home";
import {
  TypographyH1,
  TypographyLarge,
  TypographyP,
} from "@/components/Typography";
import { Button } from "@/components/ui/button";

import MuxUploader from "@mux/mux-uploader-react";
import { useState } from "react";
import RecordPage from "@/components/MuxRecordPage";
import { queryClient } from "@/main";
import { JobQuestionGet, JobQuestionGetTag } from "@/data/queries";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { handleError } from "@/data/handleError";
import { MuxUpload } from "@/components/MuxUpload";
import { LucideArrowDown } from "lucide-react";

export const UploadVideo = new Route({
  path: "/upload-video/$jobQuestionID/$jobApplicationID",
  getParentRoute: () => Home,
  onLoad: async ({ params }) => {
    await queryClient.ensureQueryData(
      [JobQuestionGetTag, params.jobQuestionID],
      () => JobQuestionGet(params.jobQuestionID)
    );
  },
  component: () => {
    const [recordType, setRecordType] = useState<"camera" | "screen" | "none">(
      "none"
    );
    const { jobQuestionID } = useParams({
      from: "/home/upload-video/$jobQuestionID/$jobApplicationID",
    });
    const { data, isLoading } = useQuery({
      queryKey: [JobQuestionGetTag, jobQuestionID],
      queryFn: () => JobQuestionGet(jobQuestionID),
      onError: (e) => toast.error(handleError(e)),
    });
    return (
      <div className={"min-h-screen"}>
        <div
          className={
            "bg-stone-900 border border-white rounded-lg px-8 py-4 mb-4 font-bold"
          }
        >
          <TypographyLarge class={"text-white"}>
            Your question answers have been recorded and sent.
          </TypographyLarge>
          <TypographyLarge class={"text-white mt-2"}>
            If you can't record this video now, please come back to this page at
            a later time to record.
          </TypographyLarge>
        </div>
        <TypographyP class={"font-bold"}>
          Question to answer with your video:
        </TypographyP>
        <TypographyH1>{data?.questionTitle}</TypographyH1>
        <div className={"flex flex-col max-w-fit space-y-6 mt-8"}>
          <TypographyP class={"flex items-center justify-center"}>
            <LucideArrowDown className={"text-gray-500"} /> Upload an existing
            video or record a new one
          </TypographyP>
          <MuxUpload />
          <Button
            size={"xl"}
            className={"bg-white"}
            onClick={() => setRecordType("camera")}
            variant={`${recordType} === "video` ? "outline" : "default"}
          >
            Record a video
          </Button>
          <Button
            size={"xl"}
            className={"bg-white"}
            onClick={() => setRecordType("screen")}
            variant={`${recordType} === "screen` ? "outline" : "default"}
          >
            Record your screen
          </Button>
        </div>
        {recordType !== "none" && <RecordPage source={recordType} />}
      </div>
    );
  },
});
