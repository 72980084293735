export const handleError = (e: any): string => {
    console.log("this one:")
    console.log( e)
    if(typeof e === "string"){
        console.log("returing string")
        return e as string
    }
    if(e.message){
        console.log("returing string 2")
        return e.message
    }
    if(e.Message){
        console.log("returing string 3")
        return e.Message
    }
    return "unknown error"
}