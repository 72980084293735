import { PatchClient, PostClient } from "@/data/http-common";
import {
  OrgJobPostCreateInput,
  OrgUserCreateArgs,
  OrgUserProfileUpdateParams,
  RestApiChangeStatusArgs,
  RestApiJobPostApplyArgs,
  RestApiJobPostApplyResponse,
  RestApiJobPostCreateResp,
  RestApiJobPostTurnOffArgs,
} from "../../types/myApi";

export const UserAuth = (args: OrgUserCreateArgs): Promise<void> => {
  return PostClient(`/api/v1/user/user`, args);
};

export const JobPostCreate = (
  args: OrgJobPostCreateInput
): Promise<RestApiJobPostCreateResp> => {
  return PostClient(`/api/v1/job-post/new`, args);
};

export const JobPostEdit = (
  args: OrgJobPostCreateInput,
  jobPostID: string | undefined
): Promise<RestApiJobPostCreateResp> => {
  return PatchClient(`/api/v1/job-post/protected/${jobPostID}/edit`, args);
};

export const JobPostApply = (
  args: RestApiJobPostApplyArgs,
  jobPostID: string
): Promise<RestApiJobPostApplyResponse> => {
  return PostClient(`/api/v1/job-post/${jobPostID}/message`, args);
};

export const ProfileUpdate = (
  args: OrgUserProfileUpdateParams,
  profileID: string
): Promise<void> => {
  return PostClient(`/api/v1/profile/${profileID}/edit`, args);
};

export const JobAppStatusUpdate = (
  jobPostID: string,
  jobAppID: string,
  args: RestApiChangeStatusArgs
): Promise<void> => {
  return PostClient(
    `/api/v1/job-application/${jobPostID}/${jobAppID}/change-status`,
    args
  );
};

export const JobStatusToggle = (
  jobPostID: string,
  args: RestApiJobPostTurnOffArgs
) => {
  return PatchClient(
    `/api/v1/job-post/protected/${jobPostID}/toggle-status`,
    args
  );
};
