import React from "react";
import ReactDOM from "react-dom/client";
import { rootRoute } from "./App";
import "./index.css";
import { RouterProvider, ReactRouter, Route } from "@tanstack/react-router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { DismissableToast } from "@/components/DismissableToast";
import { signInRoute, signUpRoute } from "@/pages/auth/auth";
import { Home } from "@/pages/home/Home";
import { homeIndex } from "@/pages/home/Index";
import { PostJob } from "@/pages/home/job-post/PostJob";
import { Jobs } from "@/pages/home/jobs";
import { JobPost } from "@/pages/home/jobs/[id]/Index";
import { EditProfile } from "@/pages/home/profile/edit";
import { ViewProfile } from "@/pages/home/profile/view";
import { JobEdit } from "@/pages/home/job-post/Edit";
import { UploadVideo } from "@/pages/home/upload-video/UploadVideo";
import { H } from "highlight.run";

const indexRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/",
  component: () => {
    return (
      <div className="p-2">
        <h3>Welcome Home!</h3>
      </div>
    );
  },
});

const routeTree = rootRoute.addChildren([
  indexRoute,
  Home.addChildren([
    homeIndex,
    PostJob,
    JobPost,
    JobEdit,
    Jobs,
    EditProfile,
    ViewProfile,
    UploadVideo,
  ]),
  signInRoute,
  signUpRoute,
]);

// Set up a ReactRouter instance
const router = new ReactRouter({
  routeTree,
  defaultPreload: "intent",
});

export const queryClient = new QueryClient();

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

H.init("xdnkqld0", {
  environment: import.meta.env.VITE_HIGHLIGHT_ENV,
  enableStrictPrivacy: false,
  tracingOrigins: true,
  networkRecording: {
    enabled: true,
    recordHeadersAndBody: true,
  },
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <DismissableToast />
    </QueryClientProvider>
  </React.StrictMode>
);
