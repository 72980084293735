import {
  TypographyH3,
  TypographyH4,
  TypographyP,
  TypographySmall,
} from "@/components/Typography";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import ReactQuill from "react-quill";
import { Button } from "@/components/ui/button";
import { useEffect, useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  AdbJobPostBusinessType,
  AdbJobPostEmploymentType,
  AdbJobPostLocationType,
  OrgJobGetResp,
  OrgJobPostCreateInput,
  OrgJobPostQuestions,
} from "../../../../types/myApi";
import { useMutation } from "@tanstack/react-query";
import { JobPostCreate, JobPostEdit } from "@/data/mutations";
import toast from "react-hot-toast";
import { handleError } from "@/data/handleError";
import { useNavigate } from "@tanstack/react-router";
import { queryClient } from "@/main";
import { JobGetTag } from "@/data/queries";
import { LucidePlus, LucideX } from "lucide-react";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Separator } from "@/components/ui/separator";
import { formatSelectValue } from "@/lib/utils";

export type EditOrPostJobProps = {
  IsEdit: boolean;
  Data?: OrgJobGetResp;
};

type Question = {
  ClientID?: string;
  Question: string;
  ServerID?: string;
};

const SeniorPaidSearchSpecialist = [
  {
    q: "Sample question 1",
    isVideo: false,
    clientID: crypto.randomUUID(),
  },
  {
    q: "Sample question 2",
    isVideo: false,
    clientID: crypto.randomUUID(),
  },
  {
    q: "Sample question 3",
    isVideo: false,
    clientID: crypto.randomUUID(),
  },
  {
    q: "Sample question 4",
    isVideo: false,
    clientID: crypto.randomUUID(),
  },
  {
    q: "Sample question 5",
    isVideo: false,
    clientID: crypto.randomUUID(),
  },
  {
    q: "Sample question 6",
    isVideo: false,
    clientID: crypto.randomUUID(),
  },
  {
    q: "Sample question 7",
    isVideo: true,
    clientID: crypto.randomUUID(),
  },
];

const JuniorPaidSearchSpecialist = [
  {
    q: "Junior Sample question 1",
    isVideo: false,
    clientID: crypto.randomUUID(),
  },
  {
    q: "Junior Sample question 2",
    isVideo: false,
    clientID: crypto.randomUUID(),
  },
  {
    q: "Junior Sample question 3",
    isVideo: false,
    clientID: crypto.randomUUID(),
  },
  {
    q: "Junior Sample question 4",
    isVideo: false,
    clientID: crypto.randomUUID(),
  },
  {
    q: "Junior Sample question 5",
    isVideo: false,
    clientID: crypto.randomUUID(),
  },
  {
    q: "Junior Sample question 6",
    isVideo: false,
    clientID: crypto.randomUUID(),
  },
  {
    q: "Junior Sample question 7",
    isVideo: true,
    clientID: crypto.randomUUID(),
  },
];

const PartTimePaidSearchSpecialist = [
  {
    q: "PartTime Sample question 1",
    isVideo: false,
    clientID: crypto.randomUUID(),
  },
  {
    q: "PartTime Sample question 2",
    isVideo: false,
    clientID: crypto.randomUUID(),
  },
  {
    q: "PartTime Sample question 3",
    isVideo: false,
    clientID: crypto.randomUUID(),
  },
  {
    q: "PartTime Sample question 4",
    isVideo: false,
    clientID: crypto.randomUUID(),
  },
  {
    q: "PartTime Sample question 5",
    isVideo: false,
    clientID: crypto.randomUUID(),
  },
  {
    q: "PartTime Sample question 6",
    isVideo: false,
    clientID: crypto.randomUUID(),
  },
  {
    q: "PartTime Sample question 7",
    isVideo: true,
    clientID: crypto.randomUUID(),
  },
];

type TemplateQuestions = {
  q: string;
  isVideo: boolean;
  clientID: string;
};

export const EditOrPostJob = (props: EditOrPostJobProps) => {
  const [value, setValue] = useState("");
  const focusRef = useRef<ReactQuill | null>(null);
  const [intakeDialogOpen, setIntakeDialogOpen] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    control,
    setValue: setFormValue,
  } = useForm<OrgJobPostCreateInput>();
  const [questions, setQuestions] = useState<Question[]>([
    { Question: "", ClientID: crypto.randomUUID() },
    { Question: "", ClientID: crypto.randomUUID() },
    { Question: "", ClientID: crypto.randomUUID() },
  ]);
  const [videoQuestion, setVideoQuestion] = useState<Question>({
    Question: "",
    ClientID: crypto.randomUUID(),
  });

  useEffect(() => {
    if (!props || !props.Data || !props.IsEdit) {
      return;
    }
    setFormValue(
      "jobPostTitle",
      props.Data?.job.jobPostTitle ? props.Data.job.jobPostTitle : ""
    );
    setFormValue("jobPostLocation", props.Data.job.jobPostLocation);
    setFormValue("jobPostBusiness", props.Data.job.jobPostBusiness);
    setFormValue("jobPostEmployment", props.Data.job.jobPostEmployment);
    setFormValue("companyName", props.Data.job.companyName.String);
    setFormValue("companyLink", props.Data.job.companyLink.String);

    setValue(props.Data?.job.jobPostBody ? props.Data.job.jobPostBody : "");
    if (props.IsEdit) {
      let questions = [] as Question[];
      props.Data?.jobQuestions?.forEach((jq) => {
        if (jq.isVideoQuestion) {
          setVideoQuestion({
            Question: jq.questionTitle,
            ServerID: jq.jobQuestionID,
            ClientID: crypto.randomUUID(),
          });
        } else {
          questions.push({
            Question: jq.questionTitle,
            ServerID: jq.jobQuestionID,
            ClientID: crypto.randomUUID(),
          });
        }
      });
      setQuestions(questions);
    }
  }, [props.IsEdit, props.Data]);

  const { mutateAsync, isLoading } = useMutation(
    (data: OrgJobPostCreateInput) => JobPostCreate(data),
    {
      onError: (err) => {
        toast.error(handleError(err));
      },
      onSuccess: () => {},
    }
  );

  const { mutateAsync: mutateEdit, isLoading: editLoading } = useMutation(
    (data: OrgJobPostCreateInput) =>
      JobPostEdit(data, props.Data?.job.jobPostID),
    {
      onError: (err) => {
        toast.error(handleError(err));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([JobGetTag, props.Data?.job.jobPostID]);
      },
    }
  );

  // Updates override all existing questions
  const updateTemplateWithQuestions = (
    templateQuestions: TemplateQuestions[]
  ): void => {
    setQuestions((prevQuestions) => {
      let copy = [] as Question[];
      templateQuestions.forEach((q) => {
        if (q.isVideo) {
          return;
        }
        copy.push({ Question: q.q, ClientID: q.clientID });
      });
      return copy;
    });
    templateQuestions.forEach((q) => {
      if (q.isVideo) {
        setVideoQuestion({ Question: q.q, ClientID: q.clientID });
      }
    });
  };

  const navigate = useNavigate();

  type valLinkResponse = {
    ValidLink: string;
    ThrowError: boolean;
  };

  const validateLink = (s: string): valLinkResponse => {
    if (s === "") {
      return { ValidLink: "", ThrowError: false };
    }
    // regex to test variable s to see if it's a valid link
    const regex = new RegExp(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/i
    );
    if (regex.test(s)) {
      return { ValidLink: s, ThrowError: false };
    }
    let potentiallyValidLink = "https://" + s;
    if (regex.test(potentiallyValidLink)) {
      return { ValidLink: potentiallyValidLink, ThrowError: false };
    }
    return { ValidLink: "", ThrowError: true };
  };

  const onSubmit: SubmitHandler<OrgJobPostCreateInput> = async (data) => {
    let validLink = validateLink(data.companyLink);
    if (validLink.ThrowError) {
      toast.error(`Invalid link: ${data.companyLink}`);
      return;
    }
    data.companyLink = validLink.ValidLink;
    console.log("submit data is", data);

    data.jobPostHTMLBody = value;
    let questionsToSubmit = [] as OrgJobPostQuestions[];
    questions?.forEach((q, i) => {
      questionsToSubmit.push({
        jobPostQuestion: q.Question,
        jobPostNumber: i,
        serverGeneratedID: q.ServerID
          ? { Uuid: q.ServerID, Valid: true }
          : { Uuid: crypto.randomUUID(), Valid: false },
        isVideoQuestion: false,
      });
    });
    questionsToSubmit.push({
      jobPostQuestion: videoQuestion.Question,
      jobPostNumber: questionsToSubmit.length + 1, // 1 video question is hard coded now
      serverGeneratedID: videoQuestion.ServerID
        ? { Uuid: videoQuestion.ServerID, Valid: true }
        : { Uuid: crypto.randomUUID(), Valid: false },
      isVideoQuestion: true,
    });
    data.jobPostQuestions = questionsToSubmit;
    if (!props.IsEdit) {
      mutateAsync(data).then((resp) => {
        navigate({
          from: "/home/post-job",
          to: "/home/jobs/$jobPostID",
          params: {
            jobPostID: resp.JobPostID,
          },
        });
      });
    } else {
      mutateEdit(data).then((resp) => {
        navigate({
          from: "/home/post-job",
          to: "/home/jobs/$jobPostID",
          params: {
            jobPostID: resp.JobPostID,
          },
        });
      });
    }
  };

  return (
    <div className={"min-h-screen max-sm:px-4"}>
      <TypographyH3>Create a new job post</TypographyH3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={"mb-4 mt-4"}>
          <Label>
            <TypographyP>Title for your job post</TypographyP>
          </Label>
          <Input
            required
            {...register("jobPostTitle")}
            placeholder={""}
            className={"bg-white mt-2 block"}
          />
        </div>
        <div className={"mb-4 mt-4"}>
          <Label>
            <TypographyP>Company Name</TypographyP>
          </Label>
          <Input
            required
            {...register("companyName")}
            placeholder={""}
            className={"bg-white mt-2 block"}
          />
        </div>
        <div className={"mb-4 mt-4"}>
          <Label>
            <TypographyP>Link to your business (not required)</TypographyP>
          </Label>
          <Input
            {...register("companyLink")}
            placeholder={""}
            className={"bg-white mt-2 block"}
          />
        </div>
        <Label>
          <TypographyP>Company Type</TypographyP>
        </Label>
        <Controller
          control={control}
          name="jobPostBusiness"
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <div>
              <Select name={name} onValueChange={onChange} defaultValue={value}>
                <SelectTrigger className="w-[180px] mt-2 bg-white">
                  <SelectValue placeholder={formatSelectValue(value)} />
                </SelectTrigger>
                <SelectContent ref={ref}>
                  <SelectItem
                    value={AdbJobPostBusinessType.JobPostBusinessTypeAgency}
                  >
                    {formatSelectValue(
                      AdbJobPostBusinessType.JobPostBusinessTypeAgency
                    )}
                  </SelectItem>
                  <SelectItem
                    value={AdbJobPostBusinessType.JobPostBusinessTypeBusiness}
                  >
                    {formatSelectValue(
                      AdbJobPostBusinessType.JobPostBusinessTypeBusiness
                    )}
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
          )}
        />
        <Label className={"block mt-4"}>
          <TypographyP>Contract Type</TypographyP>
        </Label>
        <Controller
          control={control}
          name="jobPostEmployment"
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <div>
              <Select name={name} onValueChange={onChange} defaultValue={value}>
                <SelectTrigger className="w-[180px] mt-2 bg-white">
                  <SelectValue placeholder={formatSelectValue(value)} />
                </SelectTrigger>
                <SelectContent ref={ref}>
                  <SelectItem
                    value={
                      AdbJobPostEmploymentType.JobPostEmploymentTypeFullTime
                    }
                  >
                    {formatSelectValue(
                      AdbJobPostEmploymentType.JobPostEmploymentTypeFullTime
                    )}
                  </SelectItem>
                  <SelectItem
                    value={AdbJobPostEmploymentType.JobPostEmploymentTypeHourly}
                  >
                    {formatSelectValue(
                      AdbJobPostEmploymentType.JobPostEmploymentTypeHourly
                    )}
                  </SelectItem>
                  <SelectItem
                    value={
                      AdbJobPostEmploymentType.JobPostEmploymentTypePartTime
                    }
                  >
                    {formatSelectValue(
                      AdbJobPostEmploymentType.JobPostEmploymentTypePartTime
                    )}
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
          )}
        />

        <Label className={"block mt-4"}>
          <TypographyP>Location</TypographyP>
        </Label>
        <Controller
          control={control}
          name="jobPostLocation"
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <div>
              <Select name={name} onValueChange={onChange} defaultValue={value}>
                <SelectTrigger className="w-[180px] mt-2 bg-white">
                  <SelectValue placeholder={formatSelectValue(value)} />
                </SelectTrigger>
                <SelectContent ref={ref}>
                  <SelectItem
                    value={AdbJobPostLocationType.JobPostLocationTypeRemote}
                  >
                    {formatSelectValue(
                      AdbJobPostLocationType.JobPostLocationTypeRemote
                    )}
                  </SelectItem>
                  <SelectItem
                    value={AdbJobPostLocationType.JobPostLocationTypeInOffice}
                  >
                    {formatSelectValue(
                      AdbJobPostLocationType.JobPostLocationTypeInOffice
                    )}
                  </SelectItem>
                  <SelectItem
                    value={AdbJobPostLocationType.JobPostLocationTypeHybrid}
                  >
                    {formatSelectValue(
                      AdbJobPostLocationType.JobPostLocationTypeHybrid
                    )}
                  </SelectItem>
                </SelectContent>
              </Select>
            </div>
          )}
        />
        <Label className={"mb-2 mt-4 block"}>
          <TypographyP>
            Post a description that will attract top talent:
          </TypographyP>
        </Label>
        <div onClick={() => focusRef?.current?.focus()}>
          <ReactQuill
            ref={focusRef}
            placeholder={""}
            className={"mt-2 bg-white "}
            theme="snow"
            value={value}
            onChange={setValue}
          />
        </div>
        <div className={"mt-4"}>
          <>
            <TypographyH4>Intake Questions</TypographyH4>
            <TypographyP class={"mt-2 italic"}>
              Add at least three questions to help you find the perfect
              candidate
            </TypographyP>

            <Dialog
              open={intakeDialogOpen}
              onOpenChange={(o) => setIntakeDialogOpen(o)}
            >
              {!props.IsEdit && (
                <DialogTrigger>
                  <Button
                    isSpan={true}
                    variant={"outline"}
                    className={"text-stone-900 mt-4"}
                  >
                    Start from template
                  </Button>
                </DialogTrigger>
              )}

              <DialogContent
                className={"max-h-[90vh] overflow-y-scroll sm:my-4 !text-left"}
              >
                <DialogHeader>
                  <DialogTitle className={"mt-4 !text-left"}>
                    Template 1: Senior Paid Search Specialist
                  </DialogTitle>
                  <Button
                    onClick={() => {
                      updateTemplateWithQuestions(SeniorPaidSearchSpecialist);
                      setIntakeDialogOpen(false);
                    }}
                    className={"max-w-fit"}
                  >
                    Use this template
                  </Button>
                  <div className={"divide-y divide-gray-200"}>
                    {SeniorPaidSearchSpecialist.map((q) => {
                      return (
                        <div className={"py-2 !text-left"} key={q.clientID}>
                          {q.isVideo && (
                            <TypographySmall class={"!text-left"}>
                              📽 Video Question:
                            </TypographySmall>
                          )}
                          <TypographyP class={"!text-left"}>{q.q}</TypographyP>
                        </div>
                      );
                    })}
                  </div>
                  <div className={"mt-6"}></div>
                  <Separator className={""} />
                  <div className={"mt-6"}></div>{" "}
                  <DialogTitle className={"mt-4 !text-left"}>
                    Template 2: Junior Paid Search Specialist
                  </DialogTitle>
                  <Button
                    className={"max-w-fit"}
                    onClick={() => {
                      updateTemplateWithQuestions(JuniorPaidSearchSpecialist);
                      setIntakeDialogOpen(false);
                    }}
                  >
                    Use this template
                  </Button>
                  <div className={"divide-y divide-gray-200"}>
                    {JuniorPaidSearchSpecialist.map((q) => {
                      return (
                        <div className={"py-2 !text-left"} key={q.clientID}>
                          {q.isVideo && (
                            <TypographySmall class={"!text-left"}>
                              📽 Video Question:
                            </TypographySmall>
                          )}
                          <TypographyP class={"!text-left"}>{q.q}</TypographyP>
                        </div>
                      );
                    })}
                  </div>
                  <div className={"mt-6"}></div>
                  <Separator className={""} />
                  <div className={"mt-6"}></div>
                  <DialogTitle className={"mt-4 !text-left"}>
                    Template 3: Part Time Paid Media Specialist
                  </DialogTitle>
                  <Button
                    onClick={() => {
                      updateTemplateWithQuestions(PartTimePaidSearchSpecialist);
                      setIntakeDialogOpen(false);
                    }}
                    className={"max-w-fit"}
                  >
                    Use this template
                  </Button>
                  <div className={"divide-y divide-gray-200"}>
                    {PartTimePaidSearchSpecialist.map((q) => {
                      return (
                        <div className={"py-2 !text-left"} key={q.clientID}>
                          {q.isVideo && (
                            <TypographySmall class={"!text-left"}>
                              📽 Video Question:
                            </TypographySmall>
                          )}
                          <TypographyP class={"!text-left"}>{q.q}</TypographyP>
                        </div>
                      );
                    })}
                  </div>
                </DialogHeader>
              </DialogContent>
            </Dialog>
            {questions.map((q, index) => {
              return (
                <div key={q.ClientID}>
                  <Label className={"mt-4 block mb-2"} htmlFor={`q-${index}`}>
                    Question {index + 1}
                  </Label>
                  <div className={"flex"}>
                    <Input
                      name={`q-${index}`}
                      value={q.Question}
                      required={true}
                      onChange={(e) =>
                        setQuestions((v) => {
                          let copy = [...v];
                          copy[index] = {
                            ClientID: q.ClientID,
                            Question: e.target.value,
                            ServerID: q.ServerID,
                          };
                          return copy;
                        })
                      }
                    />
                    {!q.ServerID && (
                      <Button
                        type={"button"}
                        onClick={() => {
                          setQuestions((q) => {
                            const copy = [...q];
                            copy.splice(index, 1);
                            return copy;
                          });
                        }}
                        className={
                          "rounded-full border-red-200 ml-4 px-2 hover:bg-red-100"
                        }
                        variant={"outline"}
                      >
                        <LucideX className={"text-red-600"} />
                      </Button>
                    )}
                  </div>
                </div>
              );
            })}

            <div>
              <Label className={"mt-4 block mb-2"} htmlFor={`video-question`}>
                📷 Video Question (Optional, leave blank if you don't want to
                watch applicant videos)
              </Label>
              <div className={"flex"}>
                <Input
                  name={`video-question`}
                  value={videoQuestion.Question}
                  onChange={(e) =>
                    setVideoQuestion((q) => {
                      const copy = { ...q };
                      copy.Question = e.target.value;
                      return copy;
                    })
                  }
                />
              </div>
            </div>

            <Button
              onClick={() =>
                setQuestions((q) => {
                  let copy = [...q];
                  copy.push({ Question: "", ClientID: crypto.randomUUID() });
                  return copy;
                })
              }
              className={"mt-2 flex items-center mt-4"}
              type={"button"}
              variant={"outline"}
            >
              <LucidePlus />
              Add additional question
            </Button>
          </>
        </div>
        <Button disabled={isLoading || editLoading} className={"mt-8"}>
          {props.IsEdit ? "Edit Job Post" : "Create Job Post"}
        </Button>
      </form>
    </div>
  );
};
