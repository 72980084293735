/* eslint-disable jsx-a11y/no-onchange */
import { ChangeEvent } from "react";
import AudioBars from "./audio-bars";
import { TypographyP } from "@/components/Typography";

type DeviceItems = MediaDeviceInfo[];

type DeviceList = {
  video: DeviceItems;
  audio: DeviceItems;
};

type Props = {
  isLoadingPreview: boolean;
  isRecording: boolean;
  deviceList: DeviceList;
  audioLevel: number;
  selectVideo: (evt: ChangeEvent<HTMLSelectElement>) => void;
  selectAudio: (evt: ChangeEvent<HTMLSelectElement>) => void;
  isMuted: boolean;
  muteAudioTrack: (muted: boolean) => void;
};

const CameraOptions: React.FC<Props> = ({
  isLoadingPreview,
  isRecording,
  deviceList,
  audioLevel,
  selectVideo,
  selectAudio,
  isMuted,
  muteAudioTrack,
}) => {
  return (
    <>
      <div>{isLoadingPreview && "Loading preview..."}</div>
      <div className="device-pickers mt-4 mb-4">
        {
          <>
            <TypographyP class={"mb-2"}>Pick your video device</TypographyP>
            <select
              onChange={selectVideo}
              disabled={isRecording}
              className={
                'flex h-10 w-full items-center justify-between rounded-md border border-slate-300 bg-transparent py-2 px-3 text-sm placeholder:text-slate-400 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50  max-w-fit "'
              }
              title={
                isRecording ? "Cannot change audio devices while recording" : ""
              }
            >
              {deviceList.video.map(({ label, deviceId }) => (
                <option key={deviceId} value={deviceId}>
                  {label}
                </option>
              ))}
            </select>
          </>
        }
        {
          <>
            <div className="audio-bars">
              <AudioBars
                muteAudioTrack={muteAudioTrack}
                isMuted={isMuted}
                audioLevel={audioLevel}
              />
            </div>
            <TypographyP class={"mb-2"}>Pick your audio device</TypographyP>
            <select
              onChange={selectAudio}
              disabled={isRecording}
              className={
                'flex h-10 w-full items-center justify-between rounded-md border border-slate-300 bg-transparent py-2 px-3 text-sm placeholder:text-slate-400 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50  max-w-fit "'
              }
              title={
                isRecording ? "Cannot change audio devices while recording" : ""
              }
            >
              {deviceList.audio.map(({ label, deviceId }) => (
                <option key={deviceId} value={deviceId}>
                  {label}
                </option>
              ))}
            </select>
          </>
        }
      </div>
      {/*<style jsx>{`*/}
      {/*  .device-pickers {*/}
      {/*    margin-top: 20px;*/}
      {/*  }*/}
      {/*  .audio-bars {*/}
      {/*    margin: 20px 0;*/}
      {/*  }*/}
      {/*`}</style>*/}
    </>
  );
};

export default CameraOptions;
