import { TypographySmall } from "@/components/Typography";
import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { Link } from "@tanstack/react-router";

type PaginationProps = {
  TotalPages: number;
  CurrentPage: number;
  ToPage: "/home/job-posts/$page";
};

type JobPostPageParam = {
  page?: string
}

export const Pagination = (props: PaginationProps) => {
  const [pageStart, setPageStart] = useState(0);
  const [pageEnd, setPageEnd] = useState(0);

  useEffect(() => {
    const earliestPage = props.CurrentPage - 3;
    if (earliestPage < 1) {
      setPageStart(1);
    } else {
      setPageStart(earliestPage);
    }
  }, [props.CurrentPage, props.TotalPages]);

  useEffect(() => {
    const latestPage = props.CurrentPage + 4;
    if (latestPage > props.TotalPages) {
      setPageEnd(Math.ceil(props.TotalPages));
    } else {
      setPageEnd(latestPage);
    }
  }, [props.CurrentPage, props.TotalPages]);

  if (Math.ceil(props.TotalPages) === 1) {
    return <div />;
  }
  return (
    <div>
      <TypographySmall>Page</TypographySmall>
      {pageStart !== 1 && (
          <Link
              to={props.ToPage}
              params={{page: "1"}}
              className={" px-2 py-[1px] mx-1 rounded-sm"}
          >
            <span>{1}...</span>
          </Link>
      )}
      {new Array(pageEnd - pageStart + 1).fill(1).map((d, i) => {
        return (
          <Link
            to={props.ToPage}
            params={{page: (i + pageStart).toString()}}
            activeProps={{
              className: "border border-stone-300 bg-stone-200",
            }}
            inactiveProps={{
              className: "border border-stone-200",
            }}
            className={" px-2 py-[1px] mx-1 rounded-sm"}
            key={i}
          >
            <span>{i + pageStart}</span>
          </Link>
        );
      })}
      {pageEnd !== Math.ceil(props.TotalPages) && (
          <Link
              to={props.ToPage}
              params={{page: Math.ceil(props.TotalPages).toString()}}
              className={" px-2 py-[1px] mx-1 rounded-sm"}
          >
            <span>...{Math.ceil(props.TotalPages).toString()}</span>
          </Link>
      )}
    </div>
  );
};
