import {
  AdbJobApplicationType,
  OrgJobApplication,
  RestApiChangeStatusArgs,
} from "../../types/myApi";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Column,
  ColumnFiltersState,
  createColumnHelper,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  Table,
  PaginationState,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { JobAppStatusUpdate } from "@/data/mutations";
import toast from "react-hot-toast";
import { handleError } from "@/data/handleError";
import { queryClient } from "@/main";
import { JobGetTag } from "@/data/queries";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

import MuxVideo from "@mux/mux-video-react";

import { rankItem } from "@tanstack/match-sorter-utils";
import { Input } from "@/components/ui/input";
import { LucideArrowUpRight } from "lucide-react";
import { ProfileView } from "@/pages/home/profile/view";

type JobMessageProps = {
  Applications: OrgJobApplication[];
  JobID: string;
};

type ColumnType = {
  ApplicationStatus: AdbJobApplicationType;
  emoji: string;
  browserTimeZone: string;
  jobAppID: string;
  createdAt: string;
  profileID: string;
  userEmail: string;
  userTitle: string;
  jobPostID: string;
  userID: string;
  PictureLink: string;
};

const columnHelper = createColumnHelper<ColumnType>();

const columns = [
  columnHelper.accessor("emoji", {
    cell: (info) => (
      <>
        {info.row.getValue("Status") ===
          AdbJobApplicationType.JobApplicationTypeShortlist && <span>⭐</span>}
        {info.row.getValue("Status") ===
          AdbJobApplicationType.JobApplicationTypeDecline && (
          <span className={"opacity-50"}>⭕</span>
        )}
        {info.row.getValue("Status") ===
          AdbJobApplicationType.JobApplicationTypeUpdateRequired && (
          <span>❔</span>
        )}
      </>
    ),
    footer: (info) => "",
    header: "",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("userEmail", {
    cell: (info) => (
      <span className={"flex mb-auto h-full min-w-[120px]"}>
        {info.getValue()}
      </span>
    ),
    footer: (info) => "Email",
    header: "Email",
    enableColumnFilter: true,
  }),

  columnHelper.accessor("userTitle", {
    cell: (info) => {
      const userID = info.row.getValue("userID") as string;
      const PictureLink = info.row.getValue("PictureLink") as
        | string
        | undefined;
      return (
        <Dialog>
          <DialogTrigger
            className={"flex underline items-center min-w-[180px]"}
          >
            {PictureLink && (
              <Avatar className={"mr-1"}>
                <AvatarImage
                  src={import.meta.env.VITE_CDN_HOST + PictureLink}
                />
                <AvatarFallback></AvatarFallback>
              </Avatar>
            )}
            {info.getValue()}
            <LucideArrowUpRight />
          </DialogTrigger>
          <DialogContent
            className={"max-w-[800px] overflow-y-scroll max-h-[97vh]"}
          >
            <DialogHeader>
              <ProfileView UserID={userID} />
            </DialogHeader>
          </DialogContent>
        </Dialog>
      );
    },
    footer: (info) => "Name",
    header: "Name",
    enableColumnFilter: true,
  }),
  columnHelper.accessor("jobAppID", {
    cell: (info) => <span className={"hidden"}>{info.getValue()}</span>,
    footer: () => <span className={"hidden"}></span>,
    header: () => <span className={"hidden"}></span>,
    id: "id",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("PictureLink", {
    cell: (info) => <span className={"hidden"}>{info.getValue()}</span>,
    footer: () => <span className={"hidden"}></span>,
    header: () => <span className={"hidden"}></span>,
    id: "PictureLink",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("jobPostID", {
    cell: (info) => <span className={"hidden"}>{info.getValue()}</span>,
    footer: () => <span className={"hidden"}>"jobPostID"</span>,
    header: () => <span className={"hidden"}>"jobPostID"</span>,
    id: "jobPostID",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("userID", {
    cell: (info) => <span className={"hidden"}>{info.getValue()}</span>,
    footer: () => <span className={"hidden"}>"jobPostID"</span>,
    header: () => <span className={"hidden"}>"jobPostID"</span>,
    id: "userID",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("ApplicationStatus", {
    cell: (info) => (
      <span className={"flex mb-auto h-full min-w-[160px]"}>
        <ToggleStatus
          DefaultStatus={info.getValue()}
          JobAppID={info.row.getValue("id")}
          JobPostID={info.row.getValue("jobPostID")}
        />
      </span>
    ),
    footer: (info) => "status",
    header: "Status",
    enableColumnFilter: true,
    filterFn: "auto",
    id: "Status",
  }),
  columnHelper.accessor("browserTimeZone", {
    cell: (info) => (
      <span className={"flex mb-auto h-full min-w-[160px]"}>
        {info.getValue()}
      </span>
    ),
    footer: (info) => "Computer Time Zone",
    header: "Computer Time Zone",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("createdAt", {
    cell: (info) => (
      <span className={"flex mb-auto h-full min-w-[100px]"}>
        {new Date(info.getValue()).toLocaleDateString()}
      </span>
    ),
    footer: (info) => "Date",
    header: "Date",
    enableColumnFilter: false,
  }),
];

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};
export const JobMessagesTable = (props: JobMessageProps) => {
  // The only way that I can think of telling a cell to use a video component is by adding a
  // string to the column ID
  const getColumnID = (id: string, isVideo: boolean) => {
    if (!isVideo) {
      return id;
    }
    return `${id}+is-video`;
  };

  const getColumns = () => {
    let columnnCopy = [...columns];
    if (!props || !props.Applications || props.Applications.length === 0) {
      return columnnCopy;
    }
    // Not every application has answered all the questions, this gets the longest answer
    const longestSlice = props.Applications.reduce(function (prev, current) {
      return prev.questionAndAnswers.length > current.questionAndAnswers.length
        ? prev
        : current;
    });

    // https://stackoverflow.com/a/64791605
    // https://creativecommons.org/licenses/by-sa/4.0/
    const ids = longestSlice.questionAndAnswers.map((o) => o.QuestionID);
    const filtered = longestSlice.questionAndAnswers.filter(
      ({ QuestionID }, index) => !ids.includes(QuestionID, index + 1)
    );

    filtered?.forEach((q) => {
      columnnCopy.push({
        cell: (info) => {
          if (info.column.id.includes("is-video")) {
            if (info.getValue() && (info.getValue() as string) !== "") {
              return (
                <span className={"min-w-[154px] block"}>
                  <Dialog>
                    <DialogTrigger
                      className={
                        "bg-gray-700 text-white px-4 py-2 shadow-sm rounded-lg"
                      }
                    >
                      📽 Watch video
                    </DialogTrigger>
                    <DialogContent
                      className={"max-w-[800px] overflow-y-scroll max-h-[97vh]"}
                    >
                      <DialogHeader>
                        <MuxVideo
                          className={"px-4"}
                          style={{ height: "100%", width: "100%" }}
                          playbackId={info.getValue()}
                          metadata={{
                            video_id: "video-id-123456",
                            video_title: "Application video",
                            viewer_user_id: "",
                          }}
                          streamType="on-demand"
                          controls
                        />
                      </DialogHeader>
                    </DialogContent>
                  </Dialog>
                </span>
              );
            }
          }

          if (info.getValue()?.length > 200) {
            return (
              <Popover>
                <PopoverTrigger className={"text-left min-w-[400px]"}>
                  {info.getValue().slice(0, 200)}...
                </PopoverTrigger>
                <PopoverContent className={"w-[700px]"}>
                  {info.getValue()}
                </PopoverContent>
              </Popover>
            );
          } else {
            return (
              <span className={"min-w-[400px] block"}>{info.getValue()}</span>
            );
          }
        },
        footer: (info) => q.QuestionTitle,
        id: getColumnID(q.QuestionID, q.QuestionIsVideo),
        header: q.QuestionTitle,
        accessorKey: q.QuestionID,
        enableColumnFilter: false,
      });
    });
    return columnnCopy;
  };
  const columnsAndQuestions = useMemo(() => getColumns(), [props.Applications]);

  const getData = (): any[] => {
    let data = [] as any[];
    props.Applications.forEach((a) => {
      let dataObj = {
        ApplicationStatus: a.ApplicationStatus,
        browserTimeZone: a.browserTimeZone,
        createdAt: a.createdAt,
        userEmail: a.userEmail,
        userTitle: a.userTitle,
        jobAppID: a.jobApplicationID,
        jobPostID: props.JobID,
        userID: a.userID,
        PictureLink: a.ProfilePicLink,
      } as any;
      // Each answer gets added to an dataObj where the key is the ID and the
      // answers are the value
      a.questionAndAnswers.forEach((answer) => {
        dataObj[answer.QuestionID] = answer.QuestionAnswer;
      });
      data.push(dataObj);
    });
    return data;
  };
  const tableData = useMemo(() => getData(), [props.Applications]);

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = useState({
    id: false,
    jobPostID: false,
    PictureLink: false,
    userID: false,
  });

  const table = useReactTable({
    data: tableData,
    columns: columnsAndQuestions,
    globalFilterFn: fuzzyFilter,
    getFilteredRowModel: getFilteredRowModel(),
    enableColumnFilters: true,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    state: {
      columnFilters,
      columnVisibility,
    },
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className={"w-[99vw] relative left-[calc(-50vw+50%)]"}>
      <div className={"overflow-x-scroll pb-4 pl-4"}>
        <table className={"table-auto "}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr className={"bg-gray-200 h-[1px]"} key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    className={"text-left px-4 py-2 h-[inherit] align-top"}
                    key={header.id}
                  >
                    <span className={" align-top"}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </span>
                    {header.column.getCanFilter() && header.id === "Status" && (
                      <div className={"mt-2"}>
                        <Filter
                          enumValues={[
                            AdbJobApplicationType.JobApplicationTypeDecline,
                            AdbJobApplicationType.JobApplicationTypeShortlist,
                            AdbJobApplicationType.JobApplicationTypeUpdateRequired,
                          ]}
                          column={header.column}
                          table={table}
                        />
                      </div>
                    )}
                    {header.column.getCanFilter() && header.id !== "Status" && (
                      <div className={"mt-2"}>
                        <Filter column={header.column} table={table} />
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    className={`px-4 py-3 border-x border-b border-slate-200  
                      ${
                        row.getValue("Status") ===
                        AdbJobApplicationType.JobApplicationTypeShortlist
                          ? "text-gray-800"
                          : ""
                      }
                      ${
                        row.getValue("Status") ===
                        AdbJobApplicationType.JobApplicationTypeUpdateRequired
                          ? "text-gray-600"
                          : ""
                      }
                      ${
                        row.getValue("Status") ===
                        AdbJobApplicationType.JobApplicationTypeDecline
                          ? "text-gray-400"
                          : ""
                      }
                      
                      
                      `}
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot className={"text-gray-600"}>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th
                    className={"text-left px-4 pt-4 align-top"}
                    key={header.id}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
    </div>
  );
};

function Filter({
  column,
  table,
  enumValues,
}: {
  column: Column<any, any>;
  table: Table<any>;
  enumValues?: string[];
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  if (enumValues) {
    return (
      <div>
        <Select
          onValueChange={(newValue) => {
            column.setFilterValue(newValue);
          }}
        >
          <SelectTrigger className="w-[180px] bg-white">
            <SelectValue placeholder={"filter by status"} />
          </SelectTrigger>
          <SelectContent>
            {enumValues.map((v) => {
              return (
                <SelectItem key={v} value={v}>
                  {v}
                </SelectItem>
              );
            })}
            <SelectItem value={""}>No filter</SelectItem>
          </SelectContent>
        </Select>
      </div>
    );
  }

  return typeof firstValue === "number" ? (
    <div className="flex space-x-2">
      <input
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [
            e.target.value,
            old?.[1],
          ])
        }
        placeholder={`Min`}
        className="w-24 border shadow rounded"
      />
      <input
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ""}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [
            old?.[0],
            e.target.value,
          ])
        }
        placeholder={`Max`}
        className="w-24 border shadow rounded"
      />
    </div>
  ) : (
    <Input
      type="text"
      value={(columnFilterValue ?? "") as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder={`Search...`}
      className="w-36 border shadow rounded"
    />
  );
}

type ToggleStatusProps = {
  DefaultStatus: AdbJobApplicationType;
  JobPostID: string;
  JobAppID: string;
};

export const ToggleStatus = (props: ToggleStatusProps) => {
  const { mutateAsync, isLoading } = useMutation(
    (data: RestApiChangeStatusArgs) =>
      JobAppStatusUpdate(props.JobPostID, props.JobAppID, data),
    {
      onError: (err) => {
        toast.error(handleError(err));
      },
      onSuccess: () => {
        queryClient.invalidateQueries([JobGetTag, props.JobPostID]);
      },
    }
  );

  const [value, setValue] = useState("");
  useEffect(() => {
    setValue(props.DefaultStatus);
  }, [props.DefaultStatus]);

  return (
    <div>
      <Select
        value={props.DefaultStatus}
        onValueChange={(newValue) => {
          setValue(newValue);
          mutateAsync({ newStatus: newValue as AdbJobApplicationType });
        }}
      >
        <SelectTrigger className="w-[180px] bg-white">
          <SelectValue placeholder={props.DefaultStatus} />
        </SelectTrigger>
        <SelectContent>
          <SelectItem
            value={AdbJobApplicationType.JobApplicationTypeUpdateRequired}
          >
            {AdbJobApplicationType.JobApplicationTypeUpdateRequired}
          </SelectItem>
          <SelectItem value={AdbJobApplicationType.JobApplicationTypeDecline}>
            {AdbJobApplicationType.JobApplicationTypeDecline}
          </SelectItem>
          <SelectItem value={AdbJobApplicationType.JobApplicationTypeShortlist}>
            {AdbJobApplicationType.JobApplicationTypeShortlist}
          </SelectItem>
        </SelectContent>
      </Select>
    </div>
  );
};
