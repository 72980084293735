import {Label} from "@/components/ui/label";
import {Button} from "@/components/ui/button";
import {useEffect, useState} from "react";
import {handleError} from "@/data/handleError";
import toast from "react-hot-toast";
import { queryClient } from "@/main";
import {UserDataGetTag} from "@/data/queries";
import {Loader} from "@/components/Loader";

export const AddPhoto = () => {
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [submittingImage, setSubmittingImage] = useState(false)

    const SubmitImage = async () => {
        if(!selectedImage){
            console.log("no selected image")
            return
        }
        let formData = new FormData();
        formData.append("profilePic", selectedImage)
        const res = await fetch(`${import.meta.env.VITE_API_HOST}/api/v1/user/protected/image-upload`, {
            credentials: "include",
            method: "post",
            body: formData,
        })
        await queryClient.invalidateQueries([UserDataGetTag])
    }

    useEffect(() => {
        if(selectedImage && !submittingImage){
            setSubmittingImage(true)
            console.log("starting")
            try {
                SubmitImage().then(() => {
                    setSelectedImage(null)
                    setSubmittingImage(false)
                    queryClient.invalidateQueries([UserDataGetTag])
                    console.log("done!")
                    setTimeout(() => {
                        queryClient.invalidateQueries([UserDataGetTag])
                    }, 1000)
                })
            } catch(e){
                setSelectedImage(null)
                setSubmittingImage(false)
                toast.error(handleError(e))
            }
        }
    }, [selectedImage]);
    return (
        <Label className={"relative cursor-pointer"} htmlFor={"myImage"}>
            <Button isSpan={true} variant={"actionUnderline"}>
                Add a profile photo{" "}
                <input
                    type="file"
                    name="myImage"
                    accept=".png,.jpg,.jpeg"
                    id={"myImage"}
                    className={"sr-only"}
                    onChange={(event) => {
                        setSelectedImage(
                            event?.target?.files ? event?.target?.files[0] : null
                        );
                    }}
                />
                <span className={"ml-4"}>
                {submittingImage && <Loader/>}
                </span>

            </Button>
        </Label>
    )
}