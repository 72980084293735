import { Link, Route, useParams } from "@tanstack/react-router";
import { Home } from "@/pages/home/Home";
import { queryClient } from "@/main";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { PublicProfileGet, PublicProfileGetTag } from "@/data/queries";
import { handleError } from "@/data/handleError";
import { useEffect } from "react";
import {
  TypographyH2,
  TypographyH4,
  TypographyP,
} from "@/components/Typography";
import { SanitizedHTML } from "@/components/SanitizedHTML";
import {
  LucideCalendar,
  LucideChevronLeft,
  LucideClock,
  LucideGlobe,
} from "lucide-react";
import { Button, ButtonLink } from "@/components/ui/button";

export const ViewProfile = new Route({
  getParentRoute: () => Home,
  path: "/profile/$userID/view",
  onLoad: async ({ params }) => {
    await queryClient.ensureQueryData([PublicProfileGetTag], () =>
      PublicProfileGet(params.userID)
    );
  },
  component: () => {
    const { userID } = useParams({ from: "/home/profile/$userID/view" });
    return <ProfileView UserID={userID} />;
  },
});

type ProfileViewProps = {
  UserID: string;
};

export const ProfileView = (props: ProfileViewProps) => {
  const { data, isLoading } = useQuery({
    queryKey: [PublicProfileGetTag],
    queryFn: () => PublicProfileGet(props.UserID),
    onError: (e) => toast.error(handleError(e)),
  });
  return (
    <div className={"min-h-screen px-4"}>
      {data?.UserOwnsProfile && (
        <Link
          to={"/home/profile/$userID/edit"}
          params={{ userID: props.UserID }}
          className={"flex mb-4"}
        >
          <LucideChevronLeft className={"w-6"} />
          <Button variant={"link"} className={"py-0 pl-1"} isSpan={true}>
            Edit profile
          </Button>
        </Link>
      )}
      <TypographyH2>{data?.title}</TypographyH2>
      {data?.ImageLink !== "" && data?.ImageLink && (
        <div className={"w-[200px] h-[200px] rounded-full mb-6 mt-2"}>
          <img
            className={"w-full h-full object-cover rounded-full"}
            loading={"lazy"}
            src={import.meta.env.VITE_CDN_HOST + data?.ImageLink}
          />
        </div>
      )}
      {data?.linkedinProfile && (
        <div className={"flex items-center"}>
          <LucideGlobe className={"h-4 text-gray-700"} />
          <ButtonLink
            target={"_blank"}
            className={"pl-0"}
            variant={"link"}
            href={data.linkedinProfile}
          >
            {data.linkedinProfile}
          </ButtonLink>
        </div>
      )}
      {data?.calendarLink && (
        <div className={"flex items-center"}>
          <LucideCalendar className={"h-4 text-gray-700"} />
          <ButtonLink
            target={"_blank"}
            className={"pl-0"}
            variant={"link"}
            href={data.calendarLink}
          >
            {data.calendarLink}
          </ButtonLink>
        </div>
      )}
      {data?.timeZone && (
        <div className={"flex items-center mt-2"}>
          <LucideClock className={"h-4 text-gray-700"} />
          <TypographyP class={"pl-0"}>{data.timeZone}</TypographyP>
        </div>
      )}
      {data?.youtubeEmbedLink && (
        <iframe
          className={"mt-6"}
          width="560"
          height="315"
          src={"https://www.youtube.com/embed/" + data.youtubeEmbedLink}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      )}
      <TypographyH4 class={"mb-4 mt-6"}>Bio</TypographyH4>
      <div className={"border rounded-lg border-gray-300 px-6 py-3 "}>
        <div className={"prose"}>
          <SanitizedHTML s={data?.bio} />
        </div>
      </div>
    </div>
  );
};
