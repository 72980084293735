/* eslint-disable jsx-a11y/no-onchange */
import { ChangeEvent } from "react";
import { Button } from "../ui/button";
import AudioBars from "./audio-bars";

type DeviceItems = MediaDeviceInfo[];

type DeviceList = {
  video: DeviceItems;
  audio: DeviceItems;
};

type Props = {
  isLoadingPreview: boolean;
  isMicDeviceEnabled: boolean;
  isRecording: boolean;
  deviceList: DeviceList;
  audioLevel: number;
  selectVideo: (evt: ChangeEvent<HTMLSelectElement>) => void;
  selectAudio: (evt: ChangeEvent<HTMLSelectElement>) => void;
  enableMicForScreenshare: () => void;
  isMuted: boolean;
  muteAudioTrack: (muted: boolean) => void;
};

const ScreenOptions: React.FC<Props> = ({
  isLoadingPreview,
  isRecording,
  isMicDeviceEnabled,
  deviceList,
  audioLevel,
  selectAudio,
  enableMicForScreenshare,
  isMuted,
  muteAudioTrack,
}) => {
  return (
    <>
      <div>{isLoadingPreview && "Loading preview..."}</div>
      {isMicDeviceEnabled ? (
        <div className="device-pickers">
          <div className="audio-bars">
            <AudioBars
              muteAudioTrack={muteAudioTrack}
              isMuted={isMuted}
              audioLevel={audioLevel}
            />
          </div>
          <select
            onChange={selectAudio}
            disabled={isRecording}
            className={
              'flex h-10 w-full items-center justify-between rounded-md border border-slate-300 bg-transparent py-2 px-3 text-sm placeholder:text-slate-400 focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50  max-w-fit "'
            }
            title={
              isRecording ? "Cannot change audio devices while recording" : ""
            }
          >
            {deviceList.audio.map(({ label, deviceId }) => (
              <option key={deviceId} value={deviceId}>
                {label}
              </option>
            ))}
          </select>
        </div>
      ) : (
        <div className="button mt-4">
          <Button onClick={enableMicForScreenshare}>
            Step two: 👉 Enable microphone
          </Button>
        </div>
      )}
      <style>{`
        .audio-bars {
          margin: 20px 0;
        }
      `}</style>
    </>
  );
};

export default ScreenOptions;
