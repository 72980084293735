import axios from "axios";

export const client = axios.create({
    baseURL: import.meta.env.VITE_API_HOST,
    headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Credentials": 'true',
    },
    validateStatus: function (status) {
        return true;
    },
    withCredentials: true
});

export const GetClient = async (path: string): Promise<any> => {
    let result = await client.get(path);
    if (result.status === 401){
        window.location.pathname = "/sign-in"
        return null;
    }
    if ([200, 201, 204].includes(result.status)){
        return result.data;
    }
    if([400,500].includes(result.status)){
        if (result.data && result.data.Message){
            throw new Error(result.data.Message)
        }
        throw new Error("Something went wrong")
    }
    return null;
}

export const PostClient = async (path: string, body: object): Promise<any> => {
    let result = await client.post(path, body);
    if (result.status === 401){
        window.location.pathname = "/sign-in"
        return null;
    }
    if ([200, 201, 204].includes(result.status)){
        return result.data;
    }
    if([500, 400].includes(result.status)){
        console.log("result is ", result)
        if (result.data && result.data.message){
            throw new Error(result.data.message)
        }
    if(result.data && result.data.Message){
        throw new Error(result.data.Message)
    }
        throw new Error("Something went wrong")
    }
    if(result.status === 404){
        throw new Error("not found")
    }
    return null;
}

export const PatchClient = async (path: string, body: object): Promise<any> => {
    let result = await client.patch(path, body);
    if (result.status === 401){
        window.location.pathname = "/sign-in"
        return null;
    }
    console.log("result is", result.status)
    if ([200, 201, 204].includes(result.status)){
        return result.data;
    }
    if([500, 400].includes(result.status)){
        console.log("result is ", result)
        if (result.data && result.data.message){
            throw new Error(result.data.message)
        }
        if(result.data && result.data.Message){
            throw new Error(result.data.Message)
        }
        throw new Error("Something went wrong")
    }
    if(result.status === 404){
        throw new Error("not found")
    }
    return null;
}

export const DeleteClient = async (path: string, body: object): Promise<any> => {
    let result = await client.delete(path, {data: body});
    if (result.status === 401){
        window.location.pathname = "/sign-in"
        return null;
    }
    console.log("result is", result.status)
    if ([200, 201, 204].includes(result.status)){
        return result.data;
    }
    if([400,500].includes(result.status)){
        if (result.data && result.data.message){
            throw new Error(result.data.message)
        }
        throw new Error("Something went wrong")
    }
    if(result.status === 404){
        throw new Error("not found")
    }
    return null;
}