import React from "react";

export type TypographyProps = {
    children: React.ReactNode,
    class?: string
    
}

const undefinedOrString = (s: string | undefined): string => {
    if(s){
        return s
    }
    return ""
}

export function TypographyH1(props: TypographyProps) {
    return (
        <h1 className={`${undefinedOrString(props.class)} scroll-m-20 text-4xl font-extrabold tracking-tight lg:text-5xl`}>
            {props.children}
        </h1>
    )
}

export function TypographyH2(props: TypographyProps) {
    return (
        <h2 className={`${undefinedOrString(props.class)}  scroll-m-20  pb-2 text-3xl font-semibold tracking-tight transition-colors first:mt-0 dark:border-b-slate-700`}>
            {props.children}

        </h2>
    )
}

export function TypographyH3(props: TypographyProps) {
    return (
        <h3 className={`${undefinedOrString(props.class)}  scroll-m-20 text-2xl font-semibold tracking-tight`}>
                       {props.children}
        </h3>
    )
}

export function TypographyH3Span(props: TypographyProps) {
    return (
        <span className={`${undefinedOrString(props.class)}  scroll-m-20 text-2xl font-semibold tracking-tight`}>
            {props.children}
        </span>
    )
}

export function TypographyH4(props: TypographyProps) {
    return (
        <h4 className={`${undefinedOrString(props.class)}  scroll-m-20 text-xl font-semibold tracking-tight`}>
            {props.children}

        </h4>
    )
}

export function TypographyH5(props: TypographyProps) {
    return (
        <h5 className={`${undefinedOrString(props.class)}  scroll-m-20 text-lg font-semibold tracking-tight`}>
            {props.children}

        </h5>
    )
}


export function TypographyP(props: TypographyProps) {
    return (
        <p className={`${undefinedOrString(props.class)} leading-6`}>
            {props.children}

        </p>
    )
}




export function TypographyList(props: TypographyProps) {
    return (
        <ul className={`${undefinedOrString(props.class)}  ml-6 list-disc [&>li]:mt-2`}>
            {props.children}
        </ul>
    )
}

export function TypographyListOrdered(props: TypographyProps) {
    return (
        <ol className={`${undefinedOrString(props.class)}  ml-6 list-decimal list-ordered [&>li]:mt-2`}>
            {props.children}
        </ol>
    )
}

export function TypographyLead(props: TypographyProps) {
    return (
        <p className={`${undefinedOrString(props.class)} text-xl text-slate-700 dark:text-slate-400`}>
            {props.children}
        </p>
    )
}

export function TypographyLarge(props: TypographyProps) {
    return (
        <div className={`${undefinedOrString(props.class)} text-lg font-semibold text-slate-900 dark:text-slate-50`}>
            {props.children}
        </div>
    )
}

export function TypographySmall(props: TypographyProps) {
    return (
        <small className={`${undefinedOrString(props.class)} text-sm font-medium leading-none`}>{props.children}</small>
    )
}

export function TypographySmallSpan(props: TypographyProps) {
    return (
        <span className={`${undefinedOrString(props.class)} text-sm font-medium leading-none`}>{props.children}</span>
    )
}

export function TypographySubtle(props: TypographyProps) {
    return (
        <p className={`${undefinedOrString(props.class)} text-sm text-slate-500 dark:text-slate-400`}>
            {props.children}
        </p>
    )
}