import ReactQuill from "react-quill";
import { useEffect, useRef, useState } from "react";
import { Button } from "@/components/ui/button";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  OrgJobPostQuestionAnswerInput,
  RestApiJobPostApplyArgs,
} from "../../types/myApi";
import { useMutation, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { handleError } from "@/data/handleError";
import { JobPostApply } from "@/data/mutations";
import { Link, useNavigate, useParams } from "@tanstack/react-router";
import { queryClient } from "@/main";
import {
  JobGet,
  JobGetTag,
  UserDataGet,
  UserDataGetTag,
  UserDmsGetTag,
  UserJobPostsGetTag,
} from "@/data/queries";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";

type QuestionAnswers = {
  QuestionAnswer: string;
  QuestionTitle: string;
  QuestionID: string;
};

export const MessageBox = () => {
  const { jobPostID } = useParams({ from: "/home/jobs/$jobPostID" });
  const [questionAnswers, setQuestionAnswers] = useState<QuestionAnswers[]>([]);
  // If there is a video question, we link to the video page after submitting
  const [videoQuestion, setVideoQuestion] = useState<undefined | string>(
    undefined
  );

  const navigate = useNavigate();

  const { data: jobQuestionData, isLoading: jobLoading } = useQuery({
    queryKey: [JobGetTag, jobPostID],
    queryFn: () => JobGet(jobPostID),
    onError: () => toast.error("failed to load job"),
    refetchInterval: 5000,
  });

  const { register, handleSubmit } = useForm<RestApiJobPostApplyArgs>();

  useEffect(() => {
    let videoQuestionID = jobQuestionData?.jobQuestions?.filter(
      (q) => q.isVideoQuestion
    );
    if (
      videoQuestionID &&
      videoQuestionID[0] &&
      videoQuestionID[0].jobQuestionID
    ) {
      setVideoQuestion(videoQuestionID[0].jobQuestionID);
    }
  }, [jobQuestionData]);

  const { mutateAsync, isLoading } = useMutation(
    (data: RestApiJobPostApplyArgs) => JobPostApply(data, jobPostID),
    {
      onError: (err) => {
        toast.error(handleError(err));
      },
      onSuccess: () => {
        setQuestionAnswers([]);
        toast.success("message sent!");
        queryClient.invalidateQueries([UserJobPostsGetTag, UserDmsGetTag]);
      },
    }
  );

  const onSubmit: SubmitHandler<RestApiJobPostApplyArgs> = async (data) => {
    let submitData = [] as OrgJobPostQuestionAnswerInput[];
    questionAnswers.forEach((q) => {
      submitData.push({
        questionAnswer: q.QuestionAnswer,
        questionID: q.QuestionID,
      });
    });
    if (!jobQuestionData || !jobQuestionData.jobQuestions) {
      toast.error("sorry, questions didn't load");
      return;
    }
    mutateAsync({ answers: submitData }).then((resp) => {
      if (videoQuestion) {
        navigate({
          from: "/home/jobs/$jobID",
          to: "/home/upload-video/$jobQuestionID/$jobApplicationID",
          search: {},
          params: {
            jobQuestionID: videoQuestion,
            jobApplicationID: resp.ApplicationID,
          },
        });
      }
    });
  };

  const { data: userData, isLoading: userDataLoading } = useQuery({
    queryKey: [UserDataGetTag],
    queryFn: () => UserDataGet(),
    onError: () => toast.error("failed to load profile"),
  });

  const getValue = (questionID: string) => {
    let val = questionAnswers.filter((jd) => jd.QuestionID === questionID)[0]
      ?.QuestionAnswer;
    if (!val) {
      return "";
    }
    return val;
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {jobQuestionData?.jobQuestions
          ?.filter((q) => q.isVideoQuestion === false)
          ?.map((j, index) => {
            return (
              <div className={"mt-6"} key={j.jobQuestionID}>
                <Label className={"block mb-2"}>{j.questionTitle}</Label>
                <Textarea
                  required
                  value={getValue(j.jobQuestionID)}
                  onChange={(e) => {
                    setQuestionAnswers((q) => {
                      let copy = [...q];
                      let excludingCurrentValue = copy.filter(
                        (item) => item.QuestionID != j.jobQuestionID
                      );
                      return [
                        {
                          QuestionAnswer: e.target.value,
                          QuestionID: j.jobQuestionID,
                          QuestionTitle: j.questionTitle,
                        },
                        ...excludingCurrentValue,
                      ];
                    });
                  }}
                  className={"bg-white"}
                ></Textarea>
              </div>
            );
          })}
        {userData?.bio.Valid === false ? (
          <div
            className={
              "border-orange-500 px-4 py-2 border-2 bg-orange-100 rounded-lg mt-4 flex"
            }
          >
            <p>You must add to your profile before applying.</p>{" "}
            <Link
              to={"/home/profile/$userID/edit"}
              params={{ userID: userData.userID }}
            >
              <Button className={"py-0"} variant={"link"} isSpan={true}>
                Update your profile here
              </Button>
            </Link>
          </div>
        ) : (
          <Button type={"submit"} disabled={isLoading} className={"mt-4"}>
            {videoQuestion
              ? "Submit and Answer Video Question"
              : "Submit Application"}
          </Button>
        )}
      </form>
    </div>
  );
};
