import toast, {ToastBar, Toaster} from "react-hot-toast";


export const DismissableToast = () => {
    return (
        <>
            <Toaster>
                {(t) => (
                    <ToastBar toast={t}>
                        {({icon, message}) => (
                            <>
                                {icon}
                                {message}
                                {t.type !== 'loading' && (
                                    <button className={"border-l-2 border-gray-200 pl-2"}
                                            onClick={() => toast.dismiss(t.id)}>
                                        <svg className={"fill-current text-gray-500"} xmlns="http://www.w3.org/2000/svg"
                                             width="12" height="12" viewBox="0 0 24 24">
                                            <path
                                                d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/>
                                        </svg>
                                    </button>
                                )}
                            </>
                        )}
                    </ToastBar>
                )}
            </Toaster>
        </>
    )
}