import { Route, useNavigate } from "@tanstack/react-router";
import { Home } from "@/pages/home/Home";
import "react-quill/dist/quill.snow.css";
import { EditOrPostJob } from "@/pages/home/job-post/EditOrPostJob";

export const PostJob = new Route({
  path: "post-job",
  getParentRoute: () => Home,
  component: () => {
    return <EditOrPostJob IsEdit={false} />;
  },
});

// const MenuBar = ({ editor }: { editor: any }) => {
//   if (!editor) {
//     return null;
//   }
//   return (
//     <div className={"py-3"}>
//       <Button
//         variant={"textEditor"}
//         size={"sm"}
//         type={"button"}
//         onClick={() => editor.chain().focus().toggleBold().run()}
//         disabled={!editor.can().chain().focus().toggleBold().run()}
//         className={
//           editor.isActive("bold") ? "is-active bg-stone-600 text-white" : ""
//         }
//       >
//         bold
//       </Button>
//       <Button
//         variant={"textEditor"}
//         type={"button"}
//         size={"sm"}
//         onClick={() => editor.chain().focus().toggleItalic().run()}
//         disabled={!editor.can().chain().focus().toggleItalic().run()}
//         className={
//           editor.isActive("italic") ? "is-active bg-stone-600 text-white" : ""
//         }
//       >
//         italic
//       </Button>
//       <Button
//         variant={"textEditor"}
//         type={"button"}
//         size={"sm"}
//         onClick={() => editor.chain().focus().toggleStrike().run()}
//         disabled={!editor.can().chain().focus().toggleStrike().run()}
//         className={
//           editor.isActive("strike") ? "is-active bg-stone-600 text-white" : ""
//         }
//       >
//         strike
//       </Button>
//       <Button
//         variant={"textEditor"}
//         type={"button"}
//         size={"sm"}
//         onClick={() => editor.chain().focus().setParagraph().run()}
//         className={
//           editor.isActive("paragraph")
//             ? "is-active bg-stone-600 text-white"
//             : ""
//         }
//       >
//         paragraph
//       </Button>
//       <Button
//         variant={"textEditor"}
//         type={"button"}
//         size={"sm"}
//         onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
//         className={
//           editor.isActive("heading", { level: 1 })
//             ? "is-active bg-stone-600 text-white"
//             : ""
//         }
//       >
//         h1
//       </Button>
//       <Button
//         variant={"textEditor"}
//         type={"button"}
//         size={"sm"}
//         onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
//         className={
//           editor.isActive("heading", { level: 2 })
//             ? "is-active bg-stone-600 text-white"
//             : ""
//         }
//       >
//         h2
//       </Button>
//
//       <Button
//         variant={"textEditor"}
//         type={"button"}
//         size={"sm"}
//         onClick={() => editor.chain().focus().toggleBulletList().run()}
//         className={
//           editor.isActive("bulletList")
//             ? "is-active bg-stone-600 text-white"
//             : ""
//         }
//       >
//         bullet list
//       </Button>
//     </div>
//   );
// };
