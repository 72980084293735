import { queryClient } from "@/main";
import { Route } from "@tanstack/react-router";
import { Home } from "./Home";
import {
  UserCaseStudiesGet,
  UserCaseStudiesGetTag,
  UserDataGet,
  UserDataGetTag,
  UserJobPostsGet,
  UserJobPostsGetTag,
} from "@/data/queries";
import { ActionCenter } from "@/pages/home/ActionCenter";
import { useEffect } from "react";
import { H } from "highlight.run";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";

export const homeIndex = new Route({
  getParentRoute: () => Home,
  path: "/",
  onLoad: async () => {
    await Promise.all([
      queryClient.ensureQueryData([UserDataGetTag], () => UserDataGet()),
      queryClient.ensureQueryData([UserJobPostsGetTag], () =>
        UserJobPostsGet()
      ),
      queryClient.ensureQueryData([UserCaseStudiesGetTag], () =>
        UserCaseStudiesGet()
      ),
    ]);
  },
  component: () => {
    const { data: userData, isLoading: userDataLoading } = useQuery({
      queryKey: [UserDataGetTag],
      queryFn: () => UserDataGet(),
      onError: () => toast.error("failed to load profile"),
    });
    useEffect(() => {
      if (!userData) {
        return;
      }
      H.identify(userData.email, {
        id: userData.userID,
      });
    }, [userData]);
    return (
      <div>
        <div className={"flex"}>
          <div className={"flex-1"}>
            <ActionCenter />
          </div>
        </div>
      </div>
    );
  },
});
