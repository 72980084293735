import { GetClient } from "@/data/http-common";
import {
  AdbJobQuestion,
  AdbUserBaseGetRow,
  AdbUserDm,
  AdbUserPostedJobsRow,
  OrgJobGetResp,
  OrgJobsPublicResp,
  OrgPublicUserProfile,
  OrgUserGetCaseStudiesCountResp,
} from "../../types/myApi";

export type Tag = string;

export const UserDataGetTag = `UserDataGetTag` as Tag;
export const UserDataGet = (): Promise<AdbUserBaseGetRow> => {
  return GetClient("/api/v1/user/protected/info");
};

export const UserJobPostsGetTag = `UserJobPostsGetTag` as Tag;
export const UserJobPostsGet = (): Promise<AdbUserPostedJobsRow[]> => {
  return GetClient("/api/v1/user/protected/jobs");
};
export const UserCaseStudiesGetTag = `UserCaseStudiesGetTag` as Tag;
export const UserCaseStudiesGet =
  (): Promise<OrgUserGetCaseStudiesCountResp> => {
    return GetClient("/api/v1/user/protected/case-studies");
  };
export const UserDmsGetTag = `UserDmsGetTag` as Tag;
export const UserDmsGet = (): Promise<AdbUserDm[]> => {
  return GetClient("/api/v1/user/protected/dms");
};

export const JobGetTag = `JobGetTag` as Tag;
export const JobGet = (jobID: string): Promise<OrgJobGetResp> => {
  return GetClient(`/api/v1/job-post/${jobID}`);
};

export const PublicProfileGetTag = `PublicProfileGetTag` as Tag;
export const PublicProfileGet = (
  profileID: string
): Promise<OrgPublicUserProfile> => {
  return GetClient(`/api/v1/user/protected/public-user/${profileID}`);
};

export const PublicJobsGetTag = `PublicJobsGetTag` as Tag;
export const PublicJobsGet = (
  pageNumber: string
): Promise<OrgJobsPublicResp> => {
  return GetClient(`/api/v1/job-post/all/${pageNumber}`);
};

export const JobQuestionGetTag = `JobQuestionGetTag` as Tag;
export const JobQuestionGet = (questionID: string): Promise<AdbJobQuestion> => {
  return GetClient(`/api/v1/job-post/job-question/${questionID}`);
};
