import { Link, Route, useParams, useSearch } from "@tanstack/react-router";
import { queryClient } from "@/main";
import { JobGet, JobGetTag, UserDataGet, UserDataGetTag } from "@/data/queries";
import { useMutation, useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import {
  TypographyH1,
  TypographyH2,
  TypographyLarge,
  TypographyP,
  TypographySubtle,
} from "@/components/Typography";
import { Button } from "@/components/ui/button";
import {
  LucideAlertTriangle,
  LucideCheckCircle,
  LucideChevronLeft,
  LucidePencil,
  LucideX,
} from "lucide-react";
import { MessageBox } from "@/components/MessageBox";
import { SanitizedHTML } from "@/components/SanitizedHTML";

import { Home } from "@/pages/home/Home";
import { string, z } from "zod";

import { Separator } from "@/components/ui/separator";

import {
  Dialog,
  DialogContent,
  DialogContentNormal,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

import { JobMessagesTable } from "@/components/JobMessages";
import { useState } from "react";
import { RestApiJobPostTurnOffArgs } from "../../../../../types/myApi";
import { JobStatusToggle } from "@/data/mutations";
import { handleError } from "@/data/handleError";
import { formatSelectValue } from "@/lib/utils";

export const JobPost = new Route({
  path: "jobs/$jobPostID",
  validateSearch: (search) =>
    z
      .object({
        previousPage: z.number().optional(),
      })
      .parse(search),
  getParentRoute: () => Home,
  onLoad: async ({ params: { jobPostID } }) => {
    queryClient.ensureQueryData([JobGetTag, jobPostID], () =>
      JobGet(jobPostID)
    );
  },
  component: () => {
    const { jobPostID } = useParams({ from: "/home/jobs/$jobPostID" });
    const { previousPage } = useSearch({ from: "/home/jobs/$jobPostID" });

    const [closeDialogOpen, setCloseDialogOpen] = useState(false);

    const { data, isLoading } = useQuery({
      queryKey: [JobGetTag, jobPostID],
      queryFn: () => JobGet(jobPostID),
      onError: () => toast.error("failed to load job"),
      refetchInterval: 5000,
    });
    const { data: userData, isLoading: userDataLoading } = useQuery({
      queryKey: [UserDataGetTag],
      queryFn: () => UserDataGet(),
      onError: () => toast.error("failed to load profile"),
    });

    const { mutateAsync, isLoading: toggleIsLoading } = useMutation({
      mutationFn: (data: RestApiJobPostTurnOffArgs) =>
        JobStatusToggle(jobPostID, data),
      mutationKey: [JobGetTag, jobPostID],
      onError: (err) => {
        toast.error(handleError(err));
      },
    });

    if (isLoading || !data) {
      return null;
    }

    return (
      <div>
        <div className={"max-w-[700px] mx-auto px-4 min-h-screen"}>
          {data?.job.jobPostActive === false && (
            <div
              className={
                "bg-stone-800 px-6 py-3 rounded-lg shadow-md mb-4 text-white"
              }
            >
              <LucideAlertTriangle className={"text-white mb-2"} />
              Job is marked as closed. However: you are able to share this link
              with applicants directly to receive more responses.
            </div>
          )}
          <Link
            to={"/home/job-posts/$page"}
            params={{ page: previousPage ? previousPage.toString() : "1" }}
            className={"flex"}
          >
            <LucideChevronLeft className={"w-6"} />
            <Button variant={"link"} className={"py-0 pl-1"} isSpan={true}>
              Return to all jobs
            </Button>
          </Link>
          {data?.userOwnsJob && (
            <Link
              className={"flex mt-4"}
              to={"/home/edit-job/$jobPostID"}
              params={{ jobPostID: jobPostID }}
            >
              <LucidePencil className={"w-4"} />
              <Button variant={"link"} className={"py-0 pl-1"} isSpan={true}>
                Edit Job
              </Button>
            </Link>
          )}
          {data?.userOwnsJob && data?.job.jobPostActive && (
            <Dialog
              open={closeDialogOpen}
              onOpenChange={(o) => setCloseDialogOpen(o)}
            >
              <DialogTrigger className={"flex mt-4"}>
                <LucideCheckCircle className={"w-4"} />
                <Button variant={"link"} className={"py-0 pl-1"} isSpan={true}>
                  Ready to close this job to new applicants?
                </Button>
              </DialogTrigger>
              <DialogContentNormal>
                <DialogHeader>
                  <DialogTitle>
                    Close your job application to new applicants
                  </DialogTitle>
                  <DialogDescription>
                    To reverse this action, you will have to reach out to an
                    admin
                  </DialogDescription>
                  <div className={"flex flex-col space-y-4"}>
                    <Button
                      onClick={() => setCloseDialogOpen(false)}
                      className={"my-4"}
                    >
                      Do not Close This Job
                    </Button>
                    <Button
                      onClick={() => {
                        mutateAsync({ NewJobPostStatus: false }).then(() => {
                          setCloseDialogOpen(false);
                        });
                      }}
                      disabled={toggleIsLoading}
                      className={"bg-red-500 hover:bg-red-600"}
                    >
                      Close This Job
                    </Button>
                  </div>
                </DialogHeader>
              </DialogContentNormal>
            </Dialog>
          )}
          {data.messages && (
            <div className={"mt-4"}>
              <JobMessagesTable
                JobID={jobPostID}
                Applications={data.messages}
              />
            </div>
          )}
          <TypographyH1 class={"mt-6"}>{data?.job?.jobPostTitle}</TypographyH1>
          <TypographyP class={"mt-4"}>
            Posted by: {data?.job?.companyName.String}{" "}
            {data?.job?.companyLink.Valid && (
              <a
                target={"_blank"}
                className={"underline"}
                href={data?.job?.companyLink.String}
              >
                (Company Website)
              </a>
            )}
          </TypographyP>
          <div className={"flex space-x-1 mt-2"}>
            <TypographySubtle>
              {formatSelectValue(data?.job?.jobPostEmployment)} •
            </TypographySubtle>
            <TypographySubtle>
              {formatSelectValue(data?.job?.jobPostBusiness)} •
            </TypographySubtle>
            <TypographySubtle>
              {formatSelectValue(data?.job?.jobPostLocation)}
            </TypographySubtle>
          </div>
          <div className={"prose lg:prose-lg mt-4"}>
            <SanitizedHTML s={data.job.jobPostBody} />
          </div>
          <div className={"mt-6"}>
            <Separator className={"mt-6"} />
            <TypographyH2 class={"mt-6"}>Apply for this job</TypographyH2>
            <TypographyP>
              Mention relevant experience and as much context to improve your
              chances of getting hired.
            </TypographyP>
            <TypographyP class={"my-2"}>
              <i>Note</i>: Your email will be sent along with this message for
              the job poster to respond to you directly.
            </TypographyP>
            <MessageBox />
          </div>
        </div>
      </div>
    );
  },
});
