import { Outlet, Route } from "@tanstack/react-router";
import { rootRoute } from "@/App";
import Header from "@/components/Header";

export const Home = new Route({
  getParentRoute: () => rootRoute,
  path: "home",
  component: () => {
    return (
      <div className={"h-full bg-gray-100"}>
        <Header />
        <main>
          <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            <Outlet />
          </div>
        </main>
      </div>
    );
  },
});