import { Link, Outlet, Route, useParams } from "@tanstack/react-router";
import { Home } from "@/pages/home/Home";
import { queryClient } from "@/main";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { handleError } from "@/data/handleError";
import { PublicJobsGet, PublicJobsGetTag } from "@/data/queries";
import {
  TypographyH3Span,
  TypographySmallSpan,
  TypographySubtle,
} from "@/components/Typography";
import { Pagination } from "@/pages/home/jobs/Pagination";
import { formatSelectValue } from "@/lib/utils";

export const Jobs = new Route({
  getParentRoute: () => Home,
  path: "job-posts/$page",
  onLoad: async ({ params }) => {
    queryClient.ensureQueryData([PublicJobsGetTag, params.page], () =>
      PublicJobsGet(params.page)
    );
  },
  component: () => {
    const { page } = useParams({ from: "/home/job-posts/$page" });
    const { data, isLoading } = useQuery({
      queryKey: [PublicJobsGetTag, page],
      queryFn: () => PublicJobsGet(page),
      onError: (e) => toast.error(handleError(e)),
    });
    if (isLoading) {
      return <div />;
    }
    return (
      <div className={"max-w-[700px] mx-auto px-4 min-h-screen"}>
        {data && (
          <Pagination
            ToPage={"/home/job-posts/$page"}
            TotalPages={data.CountOfJobs / 20}
            CurrentPage={parseInt(page)}
          />
        )}
        {data?.jobs?.map((post) => {
          return (
            <Link
              to={"/home/jobs/$jobPostID"}
              params={{ jobPostID: post.jobPostID }}
              className={
                "border border-gray-300 hover:border-gray-600 transition-all px-6 py-3 rounded-lg my-6 block hover:bg-stone-50 duration-100"
              }
              key={post.jobPostID}
              search={{
                previousPage: parseInt(page),
              }}
            >
              <TypographyH3Span>{post.jobPostTitle}</TypographyH3Span>
              <TypographySmallSpan class={"mt-2 block"}>
                Posted by: {post.companyName.String}
              </TypographySmallSpan>
              <div className={"flex space-x-1 mt-2"}>
                <TypographySubtle>
                  {formatSelectValue(post.jobPostEmployment)} •
                </TypographySubtle>
                <TypographySubtle>
                  {formatSelectValue(post.jobPostBusiness)} •
                </TypographySubtle>
                <TypographySubtle>
                  {formatSelectValue(post.jobPostLocation)}
                </TypographySubtle>
              </div>
              <span className={"max-h-[300px] my-2 block relative"}>
                <span className={"text-gray-700 italic text-sm"}>
                  {post.jobPostBody}
                </span>
              </span>
            </Link>
          );
        })}
      </div>
    );
  },
});
