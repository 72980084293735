import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { RestApiNullString } from "../../types/myApi";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const makeNullString = (s: string): RestApiNullString => {
  if (s && s != "") {
    return {
      String: s,
      Valid: true,
    };
  } else {
    return {
      String: "",
      Valid: false,
    };
  }
};

export const formatSelectValue = (s: string | undefined): string => {
  if (!s) {
    return "";
  }
  if (s === "") {
    return s;
  }
  let noUnderscore = s.replaceAll("_", " ");
  return capitalizeFirstLetter(noUnderscore);
};

// https://stackoverflow.com/a/1026087
// https://creativecommons.org/licenses/by-sa/4.0/
function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
