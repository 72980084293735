import {Link, Route, useNavigate, useSearch} from "@tanstack/react-router";
import {Label} from "@/components/ui/label";
import {Input} from "@/components/ui/input";
import {Button} from "@/components/ui/button";
import {SubmitHandler, useForm} from "react-hook-form";
import toast from "react-hot-toast";
import {useMutation} from "@tanstack/react-query";
import {handleError} from "@/data/handleError";
import {UserAuth} from "@/data/mutations";
import {z} from "zod"
import {rootRoute} from "@/App";
import {OrgUserCreateArgs} from "../../../types/myApi";

export const signUpRoute = new Route({
    getParentRoute: () => rootRoute,
    path: "sign-up",
    validateSearch: (search) => z.object({
        code: z.string().optional()
    }).parse(search),
    component: () => {
        return (
            <><Auth IsSignUp={true}/></>);
    },
});

export const signInRoute = new Route({
    getParentRoute: () => rootRoute,
    path: "sign-in",
    validateSearch: (search) => z.object({
        code: z.string().optional()
    }).parse(search),
    component: () => {
        return <Auth IsSignUp={false}/>;
    },
});

type AuthProps = {
    IsSignUp: boolean;
    code?: string
};

export const Auth = (props: AuthProps) => {
    const navigate = useNavigate();

    const {register, handleSubmit} = useForm<OrgUserCreateArgs>();

    const {mutateAsync, isLoading} = useMutation(
        (data: OrgUserCreateArgs) => UserAuth(data),
        {
            onError: (err) => {
                toast.error(handleError(err));
            },
            onSuccess: () => {
            },
        }
    );

    const onSubmit: SubmitHandler<OrgUserCreateArgs> = async (data) => {
        try {
            data.broswerTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        } catch(e){
            data.broswerTimeZone = "failed to get browser time zone"
        }
        mutateAsync(data).then(() => {
            navigate({
                from: signInRoute.id,
                to: "/home",
                replace: true,
            });
        });
    };

    return (
        <div className={"bg-gray-50 min-h-screen"}>
            <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-12 w-auto"
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                        alt="Your Company"
                    />
                    <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                        {props.IsSignUp
                            ? "Sign up for a ____ account"
                            : "Sign in to your account"}
                    </h2>
                    {props.IsSignUp ? (
                        <p className="mt-2 text-center text-sm text-gray-600">
                            Or{" "}
                            <Link
                                to="/sign-in"
                                className="font-medium text-indigo-600 hover:text-indigo-500"
                            >
                                sign in
                            </Link>
                        </p>
                    ) : (
                        <p className="mt-2 text-center text-sm text-gray-600">
                            Or{" "}
                            <a
                                href="/sign-up"
                                className="font-medium text-indigo-600 hover:text-indigo-500"
                            >
                                sign up
                            </a>
                        </p>
                    )}
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                        <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                            <div>
                                <Label htmlFor="email">email</Label>
                                <Input
                                    {...register("email")}
                                    autoComplete={"email"}
                                    className={"mt-2"}
                                    type="text"
                                    required
                                    id="email"
                                    placeholder="hello@gmail.com"
                                />
                            </div>
                            <div>
                                <Label htmlFor="password">password</Label>
                                <Input
                                    {...register("password")}
                                    minLength={8}
                                    autoComplete={`${props.IsSignUp ? "new-password" : "password"}  `}
                                    className={"mt-2"}
                                    type="password"
                                    required
                                    id="password"
                                    placeholder="*********"
                                />
                            </div>
                            {!props.IsSignUp && (
                                <div className="flex items-center justify-between">
                                    <div className="text-sm">
                                        <a
                                            target={"_blank"}
                                            href="mailto:me@austinpena.com?subject=Password+Reset+Request"
                                            className="font-medium text-indigo-600 hover:text-indigo-500"
                                        >
                                            Forgot your password?
                                        </a>
                                    </div>
                                </div>
                            )}

                            <div>
                                <Button
                                    disabled={isLoading}
                                    className={"w-full"}
                                    variant={"default"}
                                >
                                    {props.IsSignUp ? "Sign Up" : "Sign In"}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};
