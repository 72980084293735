import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useQuery } from "@tanstack/react-query";
import { UserDataGet, UserDataGetTag } from "@/data/queries";
import toast from "react-hot-toast";
import { LucideUser } from "lucide-react";
import { Link } from "@tanstack/react-router";
import { Button } from "./ui/button";

const user = {
  name: "Tom Cook",
  email: "tom@example.com",
  imageUrl:
    "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
};

const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "#" },
  { name: "Sign out", href: "#" },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export default function Example() {
  const { data: userData, isLoading: userDataLoading } = useQuery({
    queryKey: [UserDataGetTag],
    queryFn: () => UserDataGet(),
    onError: () => toast.error("failed to load profile"),
  });
  const navigation = [
    { name: "Home", href: "/home", current: true },
    { name: "Jobs", href: "#", current: false },
    {
      name: "Profile",
      href: "#",
      current: false,
      rest: { userID: userData?.userID ? userData.userID : "" },
    },
  ];
  return (
    <>
      <div className="min-h-full">
        {/*@ts-ignore*/}
        <Disclosure as="nav" className="bg-gray-800">
          {({ open }: { open: boolean }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 items-center justify-between">
                      <div className="max-sm:hidden flex items-baseline space-x-4 content-around w-full justify-between">
                        <div>
                        <Link
                          to={"/home"}
                          activeOptions={{
                            exact: true,
                          }}
                          activeProps={{
                            className: "bg-gray-900 text-white",
                          }}
                          inactiveProps={{
                            className:
                              "text-gray-300 hover:bg-gray-700 hover:text-white",
                          }}
                          className={classNames(
                            "px-3 py-2 rounded-md text-sm font-medium"
                          )}
                        >
                          Home
                        </Link>
                        <Link
                          to={"/home/job-posts/$page"}
                          activeOptions={{
                            exact: true,
                          }}
                          params={{
                            page: "1",
                          }}
                          activeProps={{
                            className: "bg-gray-900 text-white",
                          }}
                          inactiveProps={{
                            className:
                              "text-gray-300 hover:bg-gray-700 hover:text-white",
                          }}
                          className={classNames(
                            "px-3 py-2 rounded-md text-sm font-medium"
                          )}
                        >
                          Jobs
                        </Link>
                        <Link
                          to={"/home/profile/$userID/edit"}
                          params={{
                            userID: userData?.userID
                              ? userData.userID
                              : "no-user-id",
                          }}
                          activeProps={{
                            className: "bg-gray-900 text-white",
                          }}
                          activeOptions={{
                            exact: true,
                          }}
                          inactiveProps={{
                            className:
                              "text-gray-300 hover:bg-gray-700 hover:text-white",
                          }}
                          className={classNames(
                            "px-3 py-2 rounded-md text-sm font-medium"
                          )}
                        >
                          Your Profile
                        </Link>
                        </div>

                        <div className={"ml-auto max-sm:hidden"}>
                          <Link
                          to={"/home/post-job"}
                          >
                            <Button isSpan={true} className={"bg-white text-teal-900 hover:bg-teal-100"}>
                              Post a new job
                            </Button>
                          </Link>

                        </div>


                  </div>
                  <div className=" sm:hidden">
                    <div className="ml-4 flex items-center md:ml-6">
                      {/*<button*/}
                      {/*    type="button"*/}
                      {/*    className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"*/}
                      {/*>*/}
                      {/*    <span className="sr-only">View notifications</span>*/}
                      {/*    <BellIcon className="h-6 w-6" aria-hidden="true"/>*/}
                      {/*</button>*/}

                      {/* Profile dropdown */}
                      {/*@ts-ignore*/}
                      <Menu as="div" className="relative ml-3">
                        {/*<div>*/}
                        {/*    <Menu.Button*/}
                        {/*        className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">*/}
                        {/*        <span className="sr-only">Open user menu</span>*/}
                        {/*        <img className="h-8 w-8 rounded-full" src={user.imageUrl}*/}
                        {/*             alt=""/>*/}
                        {/*    </Menu.Button>*/}
                        {/*</div>*/}
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }: {active: boolean}) => (
                                  <a
                                    href={item.href}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block px-4 py-2 text-sm text-gray-700"
                                    )}
                                  >
                                    {item.name}
                                  </a>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                  <div className="-mr-2 flex sm:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      ) : (
                        <Bars3Icon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="md:hidden">
                <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3 flex flex-col">
                  {/*{navigation.map((item) => (*/}
                  {/*  <Disclosure.Button*/}
                  {/*    key={item.name}*/}
                  {/*    as="a"*/}
                  {/*    href={item.href}*/}
                  {/*    className={classNames(*/}
                  {/*      item.current*/}
                  {/*        ? "bg-gray-900 text-white"*/}
                  {/*        : "text-gray-300 hover:bg-gray-700 hover:text-white",*/}
                  {/*      "block px-3 py-2 rounded-md text-base font-medium"*/}
                  {/*    )}*/}
                  {/*    aria-current={item.current ? "page" : undefined}*/}
                  {/*  >*/}
                  {/*      */}
                  {/*    {item.name}*/}
                  {/*  </Disclosure.Button>*/}
                  {/*))}*/}
                    <Link
                        to={"/home"}
                        activeOptions={{
                            exact: true,
                        }}
                        activeProps={{
                            className: "bg-gray-900 text-white",
                        }}
                        inactiveProps={{
                            className:
                                "text-gray-300 hover:bg-gray-700 hover:text-white",
                        }}
                        className={classNames(
                            "px-3 py-2 rounded-md text-sm font-medium"
                        )}
                    >
                        Home
                    </Link>
                    <Link
                        to={"/home/job-posts/$page"}
                        activeOptions={{
                            exact: true,
                        }}
                        params={{
                            page: "1",
                        }}
                        activeProps={{
                            className: "bg-gray-900 text-white",
                        }}
                        inactiveProps={{
                            className:
                                "text-gray-300 hover:bg-gray-700 hover:text-white",
                        }}
                        className={classNames(
                            "px-3 py-2 rounded-md text-sm font-medium"
                        )}
                    >
                        Jobs
                    </Link>
                    <Link
                        to={"/home/profile/$userID/edit"}
                        params={{
                            userID: userData?.userID
                                ? userData.userID
                                : "no-user-id",
                        }}
                        activeProps={{
                            className: "bg-gray-900 text-white",
                        }}
                        activeOptions={{
                            exact: true,
                        }}
                        inactiveProps={{
                            className:
                                "text-gray-300 hover:bg-gray-700 hover:text-white",
                        }}
                        className={classNames(
                            "px-3 py-2 rounded-md text-sm font-medium"
                        )}
                    >
                        Your Profile
                    </Link>
                </div>
                {/*<div className="border-t border-gray-700 pt-4 pb-3">*/}
                  {/*<div className="flex items-center px-5">*/}
                  {/*  <div className="flex-shrink-0">*/}
                  {/*    <LucideUser />*/}
                  {/*  </div>*/}
                  {/*  <div className="ml-3">*/}
                  {/*    <div className="text-base font-medium leading-none text-white">*/}
                  {/*      {user.name}*/}
                  {/*    </div>*/}
                  {/*    <div className="text-sm font-medium leading-none text-gray-400">*/}
                  {/*      {user.email}*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*  <button*/}
                  {/*    type="button"*/}
                  {/*    className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"*/}
                  {/*  >*/}
                  {/*    <span className="sr-only">View notifications</span>*/}
                  {/*    <BellIcon className="h-6 w-6" aria-hidden="true" />*/}
                  {/*  </button>*/}
                  {/*</div>*/}
                  {/*<div className="mt-3 space-y-1 px-2">*/}
                  {/*  {userNavigation.map((item) => (*/}
                  {/*    <Disclosure.Button*/}
                  {/*      key={item.name}*/}
                  {/*      as="a"*/}
                  {/*      href={item.href}*/}
                  {/*      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"*/}
                  {/*    >*/}
                  {/*      {item.name}*/}
                  {/*    </Disclosure.Button>*/}
                  {/*  ))}*/}
                  {/*</div>*/}
                {/*</div>*/}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </>
  );
}