import * as React from "react";
import { VariantProps, cva } from "class-variance-authority";

import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2   disabled:opacity-50  disabled:pointer-events-none  data-[state=open]:bg-slate-100 ",
  {
    variants: {
      variant: {
        default: "bg-slate-900 text-white hover:bg-slate-700  ",
        outline: "bg-transparent border border-slate-300 hover:bg-slate-100  ",
        subtle: "bg-stone-50 text-slate-900 hover:bg-stone-200  ",
        actionUnderline:
          "bg-transparent text-blue-500 underline decoration-blue-500 hover:transparent hover:text-sky-400",
        ghost:
          "bg-transparent hover:bg-slate-100    data-[state=open]:bg-transparent ",
        textEditor:
          "bg-transparent hover:bg-stone-800 hover:text-white   data-[state=open]:bg-transparent ",

        link: "bg-transparent underline-offset-4 hover:underline text-slate-900  hover:bg-transparent dark:hover:bg-transparent",
      },
      size: {
        default: "py-2 px-4",
        sm: " px-2 rounded-md",
        lg: "py-2 px-8 rounded-md",
        xl: "py-2 px-8 rounded-md text-xl",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  isSpan?: boolean;
  isLink?: boolean;
  href?: string;
}

export interface ButtonLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    VariantProps<typeof buttonVariants> {}

const ButtonLink = React.forwardRef<HTMLAnchorElement, ButtonLinkProps>(
  ({ className, variant, size, ...props }, ref) => {
    return (
      <a
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
ButtonLink.displayName = "ButtonLink";

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, isSpan, isLink, ...props }, ref) => {
    if (isSpan) {
      return (
        <span
          className={cn(buttonVariants({ variant, size, className }))}
          ref={ref}
          {...props}
        />
      );
    }
    return (
      <button
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants, ButtonLink };
