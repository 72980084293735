/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum AdbJobApplicationType {
  JobApplicationTypeUnset = "unset",
  JobApplicationTypeShortlist = "short list",
  JobApplicationTypeSelected = "selected",
  JobApplicationTypeIgnored = "ignored",
  JobApplicationTypeUpdateRequired = "Update Required",
  JobApplicationTypeDecline = "Decline",
}

export interface AdbJobPost {
  companyLink: RestApiNullString;
  companyName: RestApiNullString;
  createdAt: string;
  fkUserCreatorID: string;
  jobPostActive: boolean;
  jobPostBody: string;
  jobPostBusiness: AdbJobPostBusinessType;
  jobPostEmployment: AdbJobPostEmploymentType;
  jobPostID: string;
  jobPostLocation: AdbJobPostLocationType;
  jobPostTitle: string;
}

export enum AdbJobPostBusinessType {
  JobPostBusinessTypeAgency = "agency",
  JobPostBusinessTypeBusiness = "business",
}

export enum AdbJobPostEmploymentType {
  JobPostEmploymentTypeHourly = "hourly",
  JobPostEmploymentTypeFullTime = "full_time",
  JobPostEmploymentTypeTemp = "temp",
  JobPostEmploymentTypePartTime = "part_time",
}

export enum AdbJobPostLocationType {
  JobPostLocationTypeRemote = "remote",
  JobPostLocationTypeInOffice = "in_office",
  JobPostLocationTypeHybrid = "hybrid",
}

export interface AdbJobQuestion {
  createdAt: string;
  fkJobPost: string;
  isVideoQuestion: boolean;
  jobQuestionID: string;
  questionIsBoolean: boolean;
  questionNumber: number;
  questionTitle: string;
}

export interface AdbJobsPublicGetRow {
  companyLink: RestApiNullString;
  companyName: RestApiNullString;
  createdAt: string;
  jobPostActive: boolean;
  jobPostBody: string;
  jobPostBusiness: AdbJobPostBusinessType;
  jobPostEmployment: AdbJobPostEmploymentType;
  jobPostID: string;
  jobPostLocation: AdbJobPostLocationType;
  jobPostTitle: string;
}

export interface AdbUserBaseGetRow {
  bio: RestApiNullString;
  browserTimeZone: RestApiNullString;
  byline: RestApiNullString;
  calendarLink: RestApiNullString;
  createdAt: string;
  email: string;
  fkUserProfileID: string;
  isAdmin: boolean;
  linkedinProfile: RestApiNullString;
  pictureLink: RestApiNullString;
  primarilyJobSeeker: boolean;
  timeZone: RestApiNullString;
  title: RestApiNullString;
  userID: string;
  userProfileID: string;
  youtubeEmbedLink: RestApiNullString;
}

export interface AdbUserDm {
  body: string;
  createdAt: string;
  fkUserFrom: string;
  fkUserTo: string;
  subject: string;
  userDmID: string;
}

export interface AdbUserPostedJobsRow {
  createdAt: string;
  jobPostActive: boolean;
  jobPostID: string;
  jobPostTitle: string;
  replies: number;
}

/** generic error message */
export interface HelpersErrorMessage {
  message?: string;
}

/** return value for job apps */
export interface OrgJobApplication {
  ApplicationStatus: AdbJobApplicationType;
  /** Might or might not be set */
  ProfilePicLink: string;
  browserTimeZone: string;
  createdAt: string;
  jobApplicationID: string;
  questionAndAnswers: OrgQuestionAndAnswer[];
  userEmail: string;
  userID: string;
  userTitle: string;
}

/** response from a job */
export interface OrgJobGetResp {
  job: AdbJobPost;
  jobQuestions: AdbJobQuestion[];
  /** Must be explicitly added if the user is the owner */
  messages: OrgJobApplication[];
  userOwnsJob: boolean;
}

/** required to create a job post */
export interface OrgJobPostCreateInput {
  companyLink: string;
  companyName: string;
  jobPostBusiness: AdbJobPostBusinessType;
  jobPostEmployment: AdbJobPostEmploymentType;
  jobPostHTMLBody: string;
  jobPostLocation: AdbJobPostLocationType;
  jobPostQuestions: OrgJobPostQuestions[];
  jobPostTitle: string;
}

export interface OrgJobPostQuestionAnswerInput {
  questionAnswer: string;
  questionID: string;
}

export interface OrgJobPostQuestions {
  /** For video questions */
  isVideoQuestion: boolean;
  jobPostNumber: number;
  jobPostQuestion: string;
  /** When editing a question, this field should be set */
  serverGeneratedID: OrgNullUUID;
}

/** public response for the jobs */
export interface OrgJobsPublicResp {
  CountOfJobs: number;
  jobs: AdbJobsPublicGetRow[];
}

export interface OrgMuxCreateDirectUploadResp {
  id: string;
  url: string;
}

export interface OrgNullUUID {
  Uuid: string;
  /** Valid is true if UUID is not NULL */
  Valid: boolean;
}

export interface OrgPublicUserProfile {
  ImageLink: string;
  UserOwnsProfile: boolean;
  bio: string;
  calendarLink: string;
  linkedinProfile: string;
  timeZone: string;
  title: string;
  youtubeEmbedLink: string;
}

export interface OrgQuestionAndAnswer {
  QuestionAnswer: string;
  QuestionID: string;
  QuestionIsVideo: boolean;
  QuestionNumber: number;
  QuestionTitle: string;
  answerID: string;
}

/** User account information with user id and username */
export interface OrgUserCreateArgs {
  broswerTimeZone: string;
  email: string;
  /**
   * @minLength 8
   * @maxLength 72
   */
  password: string;
}

export interface OrgUserGetCaseStudiesCountResp {
  count: number;
}

/** for updating a profile */
export interface OrgUserProfileUpdateParams {
  YoutubeEmbedLink: string;
  bio: string;
  byline: string;
  calendarLink: string;
  id: string;
  linkedinProfile: string;
  primarilyJobSeeker: boolean;
  timeZone: string;
  title: string;
}

export interface RestApiChangeStatusArgs {
  newStatus: AdbJobApplicationType;
}

export interface RestApiHelloResponse {
  hello: string;
}

export interface RestApiJobPostApplyArgs {
  answers: OrgJobPostQuestionAnswerInput[];
}

export interface RestApiJobPostApplyResponse {
  ApplicationID: string;
}

/** toggle */
export interface RestApiJobPostCreateResp {
  JobPostID: string;
}

export interface RestApiJobPostTurnOffArgs {
  NewJobPostStatus: boolean;
}

export interface RestApiNullString {
  String: string;
  Valid: boolean;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "//0.0.0.0:8080/api/v1" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Golang Rest API
 * @version 1.0
 * @license Apache 2.0 (http://www.apache.org/licenses/LICENSE-2.0.html)
 * @termsOfService http://swagger.io/terms/
 * @baseUrl //0.0.0.0:8080/api/v1
 * @contact API Support <support@swagger.io> (http://www.swagger.io/support)
 *
 * This is a sample server celler server.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  hello = {
    /**
     * No description
     *
     * @name HelloList
     * @request GET:/hello
     */
    helloList: (params: RequestParams = {}) =>
      this.request<RestApiHelloResponse, any>({
        path: `/hello`,
        method: "GET",
        ...params,
      }),
  };
  jobPost = {
    /**
     * No description
     *
     * @name PostJobPost
     * @request POST:/job-post/new
     */
    postJobPost: (params: RequestParams = {}) =>
      this.request<OrgMuxCreateDirectUploadResp, HelpersErrorMessage>({
        path: `/job-post/new`,
        method: "POST",
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @name ProtectedCaseStudiesList
     * @request GET:/user/protected/case-studies
     */
    protectedCaseStudiesList: (params: RequestParams = {}) =>
      this.request<OrgUserGetCaseStudiesCountResp, HelpersErrorMessage>({
        path: `/user/protected/case-studies`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ProtectedDmsList
     * @request GET:/user/protected/dms
     */
    protectedDmsList: (params: RequestParams = {}) =>
      this.request<AdbUserDm[], HelpersErrorMessage>({
        path: `/user/protected/dms`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ProtectedInfoList
     * @request GET:/user/protected/info
     */
    protectedInfoList: (params: RequestParams = {}) =>
      this.request<AdbUserBaseGetRow, HelpersErrorMessage>({
        path: `/user/protected/info`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ProtectedJobsList
     * @request GET:/user/protected/jobs
     */
    protectedJobsList: (params: RequestParams = {}) =>
      this.request<AdbUserPostedJobsRow[], HelpersErrorMessage>({
        path: `/user/protected/jobs`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name UserCreate
     * @request POST:/user/user
     */
    userCreate: (request: OrgUserCreateArgs, params: RequestParams = {}) =>
      this.request<void, HelpersErrorMessage>({
        path: `/user/user`,
        method: "POST",
        body: request,
        type: ContentType.Json,
        ...params,
      }),
  };
}
